import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import PieChart from 'components/charts/PieChart';
import { MdCircle } from 'react-icons/md';
import { limitDpTo, numberWithCommas } from 'utils/numbers';

export default function Conversion(props) {
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const { piechartData, piechartOptions, vertical, hasDetails } = props;
  const sum = piechartData.reduce((a, b) => a + b, 0);

  const filteredData = piechartData.filter((value) => value > 0.01);
  const filteredLabels = piechartOptions.labels.filter((_, index) => piechartData[index] > 0.01);
  const filteredColors = piechartOptions.colors.filter((_, index) => piechartData[index] > 0.01);

  const updatedPiechartOptions = {
    ...piechartOptions,
    labels: filteredLabels,
    colors: filteredColors
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('#162744', '#DFDFDF');
  const cardColor = useColorModeValue('#DFDFDF', '#162744');
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  return (
    <Card
      p="20px"
      align="center"
      direction="column"
      w="100%"
      h="100%"
      border={`1px solid ${borderColor}`}
    >
      <Flex
        px={{ base: '0px', '2xl': '10px' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="22px" fontWeight="600">
          Fund Allocation
        </Text>
      </Flex>
      <Flex alignItems="center" flexDirection={vertical ? 'column' : 'row'}>
        <Box>
          <PieChart
            h="100%"
            w="100%"
            chartData={filteredData}
            chartOptions={updatedPiechartOptions}
          />
        </Box>
        <Card
          bg={cardColor}
          border={`1px solid ${borderColor}`}
          flexDirection="row"
          boxShadow={cardShadow}
          w="50%"
          p="15px"
          px="20px"
          mx="auto"
        >
          <Flex flexDirection="column" w="100%">
            <Flex align="center" justifyContent="space-between" margin="-10px 0">
              <Icon
                as={MdCircle}
                width="1rem"
                height="1rem"
                marginRight="12px"
                color="transparent"
              />
              <Box
                direction="column"
                w="100%"
                px="0px"
                margin="8px 0"
                padding="8px 0"
                borderRadius="8px"
              >
                <Text color={textColor} textAlign="left" fontSize=".875rem" fontWeight="bold">
                  Fund
                </Text>
              </Box>
              <Box direction="column" px="0px" margin="8px 0" padding="8px 0" borderRadius="8px">
                <Text color={textColor} fontSize=".875rem" fontWeight="bold">
                  Portfolio
                </Text>
              </Box>
              <Box
                direction="column"
                w="50%"
                px="0px"
                margin="8px 0"
                padding="8px 0"
                borderRadius="8px"
              >
                <Text color={textColor} fontSize=".875rem" fontWeight="bold">
                  AUM
                </Text>
              </Box>
            </Flex>
            {filteredLabels.map((label, index) => (
              <Flex key={index} align="center" justifyContent="space-between" margin="-10px 0">
                <Icon
                  as={MdCircle}
                  width="1rem"
                  height="1rem"
                  color={filteredColors[index]}
                  marginRight="12px"
                />
                <Box
                  direction="column"
                  w="100%"
                  px="0px"
                  margin="8px 0"
                  padding="8px 0"
                  borderRadius="8px"
                >
                  <Text color={textColor} textAlign="left" fontSize=".875rem">
                    {label}
                  </Text>
                </Box>
                {hasDetails ? (
                  <Box
                    direction="column"
                    px="0px"
                    margin="8px 0"
                    padding="8px 0"
                    borderRadius="8px"
                  >
                    <Text color={textColor} fontSize=".875rem">
                      {limitDpTo((filteredData[index] / sum) * 100, 2).toFixed(2)}%
                    </Text>
                  </Box>
                ) : (
                  <></>
                )}
                {hasDetails ? (
                  <Box
                    direction="column"
                    w="50%"
                    px="0px"
                    margin="8px 0"
                    padding="8px 0"
                    borderRadius="8px"
                  >
                    <Text color={textColor} fontSize=".875rem">
                      ${numberWithCommas(limitDpTo(filteredData[index], 2).toFixed(2))}
                    </Text>
                  </Box>
                ) : (
                  <></>
                )}
              </Flex>
            ))}
          </Flex>
        </Card>
      </Flex>
    </Card>
  );
}
