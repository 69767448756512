/* eslint-disable */
import { Box, Button, Input, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import ConfirmModal from 'components/confirmModal/ConfirmModal';
import { useState } from 'react';
import { changeProfile } from 'service/api';

export default function EditProfileForm(props) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const inputBg = useColorModeValue('#DFDFDF', '#162744');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('Your profile will be updated after clicking confirm!');
  const [disableConfirm, setDisableConfirm] = useState(false);

  const handleFirstname = (event) => {
    setFirstname(event.target.value);
  };

  const handleLastname = (event) => {
    setLastname(event.target.value);
  };

  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleOpenModal = async () => {
    if (firstname === '' && lastname === '' && phoneNumber === '') {
      setMessage('Please fill in the fields');
      setDisableConfirm(true);
    } else if (phoneNumber === '') {
      setMessage("Phone number is not provided and it won't be updated");
      setDisableConfirm(false);
    } else if (firstname === '' || lastname === '') {
      setMessage("Full name is not provided and it won't be updated");
      setDisableConfirm(false);
    } else {
      setMessage('Your profile will be updated after clicking confirm!');
      setDisableConfirm(false);
    }
    onOpen();
  };

  const handleSubmit = async () => {
    try {
      if (firstname === '' || lastname === '') {
        await changeProfile('', phoneNumber);
      } else {
        await changeProfile(firstname + ' ' + lastname, phoneNumber);
      }
      setMessage('Profile successfully updated!');
      const reload = () => {
        window.location.reload(true);
      };
      setTimeout(reload, 1000);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Card
      direction="column"
      w="100%"
      p="0px"
      mb="48px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      border={`1px solid ${borderColor}`}
    >
      <Box direction="column" w="100%" padding="20px" borderRadius="8px">
        <Text
          color={textColor}
          fontSize="16px"
          fontWeight="700"
          lineHeight="100%"
          marginBottom="0.625rem"
        >
          First Name
        </Text>
        <Input
          fontSize="sm"
          bg={inputBg}
          color={inputText}
          fontWeight="500"
          _placeholder={{ color: 'gray.400', fontSize: '14px' }}
          borderRadius="30px"
          size="lg"
          placeholder="e.g. John"
          mb="24px"
          onChange={handleFirstname}
          value={firstname}
        />

        <Text
          color={textColor}
          fontSize="16px"
          fontWeight="700"
          lineHeight="100%"
          marginBottom="0.625rem"
        >
          Last Name
        </Text>
        <Input
          fontSize="sm"
          bg={inputBg}
          color={inputText}
          fontWeight="500"
          _placeholder={{ color: 'gray.400', fontSize: '14px' }}
          placeholder="e.g. Doe"
          mb="24px"
          onChange={handleLastname}
          value={lastname}
          borderRadius="30px"
          size="lg"
        />

        <Text
          color={textColor}
          fontSize="16px"
          fontWeight="700"
          lineHeight="100%"
          marginBottom="0.625rem"
        >
          Phone Number
        </Text>
        <Input
          fontSize="sm"
          bg={inputBg}
          color={inputText}
          fontWeight="500"
          _placeholder={{ color: 'gray.400', fontSize: '14px' }}
          borderRadius="30px"
          size="lg"
          placeholder="Min. 8 characters"
          mb="24px"
          onChange={handlePhoneNumber}
          value={phoneNumber}
        />

        <Button
          bg={bgButton}
          color={buttonTextColor}
          _hover={bgHover}
          width="100%"
          onClick={handleOpenModal}
        >
          Update Profile
        </Button>
      </Box>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        message={message}
        disabled={disableConfirm}
        buttonText="Continue editing"
      />
    </Card>
  );
}
