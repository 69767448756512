import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';

import MiniCalendar from 'components/calendar/MiniCalendar';
import Card from 'components/card/Card';
import SummaryPieCard from 'views/agent/clientOverview/components/SummaryPieCard';

import toast from 'react-hot-toast';
import {
  dateToString,
  formatDate,
  fundswitch,
  getFundIds,
  getFundsPriceLatest,
  getFundsPricesByDay,
  premiumApportionment,
  reducePremium
} from 'service/api';
import { formatDateDDMMYYYY } from 'utils/string';
import FundAllocationCard from '../modalComponents/FundAllocationCard';
import PremiumEdit from '../modalComponents/PremiumEdit';
import UnitsAddition from '../modalComponents/UnitsAddition';
import FundsOverview from './FundsOverview';

export class FundInfo {
  constructor(id, num) {
    this.id = id;
    this.num = num;
  }
}

export default function TransactionModal(props) {
  const {
    isOpen,
    onClose,
    transactionType,
    columnsData,
    tableData,
    piechartData,
    piechartOptions,
    product,
    onRefresh,
    fundsData,
    transactions,
    showWelcomeBonus,
    todaysPrices
  } = props;

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardColor = useColorModeValue('#DFDFDF', '#162744');
  const inputBg = useColorModeValue('whiteAlpha.300', 'whiteAlpha.100');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const [premiumAmt, setPremiumAmt] = useState(0);
  const [premiumType, setPremiumType] = useState('monthly');
  const [fundOptions, setFundOptions] = useState([]);
  const [filteredFundOptions, setFilteredFundOptions] = useState([]);
  const [funds, setFunds] = useState([]);
  const [fundsB, setFundsB] = useState([]);
  const [filteredFundBOptions, setFilteredFundBOptions] = useState([]);
  const [fundPrices, setFundPrices] = useState([]);
  const [date, setDate] = useState(new Date());
  const [changer, setChanger] = useState(true);
  const [transactionInfo, setTransactionInfo] = useState({});

  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  useEffect(() => {
    setChanger(() => !changer);
  }, [funds, fundsB]);

  const refresh = () => {
    toastSuccess('Transaction is successful!');
    onClose();
    onRefresh();
  };
  const handleClose = () => {
    onClose();
    setFunds([]);
    setFundsB([]);
  };

  const filterOptions = (options) => {
    const ids = tableData.map((t) => parseInt(t.id));
    const filtered = options.filter((o) => ids.includes(parseInt(o)));
    return filtered;
  };

  const editFundId = (index, id) => {
    const newFunds = funds.map((fund, i) => {
      if (i === index) {
        return { ...fund, id };
      }
      return fund;
    });
    setFunds(newFunds);
  };

  const editFundNum = (index, num) => {
    const x = parseFloat(num);
    let newFunds = funds;
    if (isNaN(x)) {
      newFunds[index].num = '0';
    } else {
      newFunds[index].num = num.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    }
    setFunds([...newFunds]);
  };

  const getFirstUnselectedFundId = (current, options) => {
    const ids = current.map((t) => parseInt(t.id));
    let i = 0;
    for (; i <= options.length; i++) {
      if (!ids.includes(options[i])) {
        return options[i];
      }
    }
    return 0;
  };

  const addFund = () => {
    if (funds.length >= fundOptions.length) {
      return;
    }
    let newFund = new FundInfo(getFirstUnselectedFundId(funds, fundOptions), '');
    let newFunds = funds.concat(newFund);
    setFunds(newFunds);
  };

  const addFilteredFund = () => {
    if (funds.length >= filteredFundOptions.length) {
      return;
    }
    let newFund = new FundInfo(getFirstUnselectedFundId(funds, filteredFundOptions), '');
    let newFunds = funds.concat(newFund);
    setFunds(newFunds);
  };

  const removeFund = () => {
    if (funds.length <= 0) {
      return;
    }
    funds.pop();
    setFunds([...funds]);
  };

  const editFundIdB = (index, id) => {
    let newFunds = fundsB;
    fundsB[index].id = id;
    setFundsB([...newFunds]);
  };

  const editFundNumB = (index, num) => {
    const x = parseFloat(num);
    let newFunds = fundsB;
    if (isNaN(x)) {
      newFunds[index].num = '0';
    } else {
      newFunds[index].num = num.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    }
    setFundsB([...newFunds]);
  };

  const addFundB = () => {
    if (fundsB.length >= fundOptions.length) {
      return;
    }
    let newFund = new FundInfo(getFirstUnselectedFundId(fundsB, filteredFundBOptions), '');
    let newFunds = fundsB.concat(newFund);
    setFundsB(newFunds);
  };

  const removeFundB = () => {
    if (fundsB.length <= 0) {
      return;
    }
    fundsB.pop();
    setFundsB([...fundsB]);
  };

  const handlePremium = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setPremiumAmt(0);
    } else {
      setPremiumAmt(x);
    }
  };

  const handlePremiumType = (event) => {
    setPremiumType(event.target.value);
  };

  const tupleArrToDic = (arr) => {
    let d = {};
    for (let i = 0; i < arr.length; i++) {
      d[arr[i].id] = parseFloat(arr[i].num);
    }

    return d;
  };

  const getWithdrawalValue = () => {
    const res = funds;
    const withdrawalSum = res.reduce((value, ele) => {
      return (
        value + ele.num
      );
    }, '');

    return withdrawalSum;
  };

  function formatFunds(funds) {
    return funds.map((fund) => fund.id);
  }

  const handleSubmit = async () => {    
    const res = funds;
    let s = new Set();
    const prices = await getFundsPricesByDay(formatDateDDMMYYYY(date));
    
    const checkFunds = (fundsArray) => {
      for (let i = 0; i < fundsArray.length; i++) {
        if (s.has(fundsArray[i].id)) {
          toastError('Duplicate entries');
          return false;
        }
        s.add(fundsArray[i].id);
        if (isNaN(fundsArray[i].num)) {
          toastError('Invalid quantity');
          return false;
        }
      }
      return true;
    };
    
    switch (transactionType) {
      case 1: {
        if (!checkFunds(res)) return;
        const allocation = fundsB;

        s = new Set();
        if (!checkFunds(allocation)) return;
        let allocationFunds = allocation.map(el => el.num).reduce((a, b) => a + Number(b), 0);
    
        if (allocationFunds !== 100) {
          toastError('Allocations should add up to 100%');
    
          return;
        }

        const funds = formatFunds(fundsB).concat(formatFunds(res));
        const filteredTransactionInfo = Object.keys(transactionInfo).reduce((acc, key) => {
          acc[key] = Object.keys(transactionInfo[key])
            .filter((fundId) => funds.includes(Number(fundId)))
            .reduce((obj, fundId) => {
              obj[fundId] = transactionInfo[key][fundId];
              return obj;
            }, {});
          return acc;
        }, {});
        const funds2 = formatFunds(fundsB);
        const filteredTransactionInfo2 = Object.keys(transactionInfo).reduce((acc, key) => {
          acc[key] = Object.keys(transactionInfo[key])
            .filter((fundId) => funds2.includes(Number(fundId)))
            .reduce((obj, fundId) => {
              obj[fundId] = transactionInfo[key][fundId];
              return obj;
            }, {});
          return acc;
        }, {});

        const switchOut = tupleArrToDic(res);
        const pricesForFunds = Object.keys(transactionInfo.value)
        .map((key) => ({
          [key]: prices[Number(key)]['bid_price']
        }))
        .reduce((acc, obj) => Object.assign(acc, obj), {});
      
        try {
          const result = await fundswitch(
            product.ID,
            switchOut,
            filteredTransactionInfo2.value,
            dateToString(date),
            filteredTransactionInfo.value,
            filteredTransactionInfo.units,
            filteredTransactionInfo.balanceUnits,
            pricesForFunds
          ).then(() => {
            refresh();
          });
        } catch (err) {
          toastError(err);
        }
        break;
      }

      case 2: {
        if (!checkFunds(res)) return;

        const funds = formatFunds(res);
        const filteredTransactionInfo = Object.keys(transactionInfo).reduce((acc, key) => {
          acc[key] = Object.keys(transactionInfo[key])
            .filter((fundId) => funds.includes(Number(fundId)))
            .reduce((obj, fundId) => {
              obj[fundId] = transactionInfo[key][fundId];
              return obj;
            }, {});
          return acc;
        }, {});

        const switchOut = tupleArrToDic(res);

        const pricesForFunds = Object.keys(transactionInfo.value)
        .map((key) => ({
          [key]: prices[Number(key)]['bid_price']
        }))
        .reduce((acc, obj) => Object.assign(acc, obj), {});

        try {
          await fundswitch(
            product.ID,
            switchOut,
            { 1: 1 },
            dateToString(date),
            filteredTransactionInfo.value,
            filteredTransactionInfo.units,
            filteredTransactionInfo.balanceUnits,
            pricesForFunds,
            'Withdraw'
          ).finally(() => {
            toastSuccess('Transaction is successful!');
            refresh();
          });
        } catch (err) {
          toastError(err + 'HERERERER');
        }
        break;
      }

      case 3: {
        if (!checkFunds(res)) return;

        const apportionment = tupleArrToDic(res);
        try {
          await premiumApportionment(product.ID, apportionment);

          refresh();

          toastSuccess('Transaction is successful!');
        } catch (err) {
          toastError(err);
        }
        break;
      }

      case 4: {
        if (premiumAmt < 0.0001) {
          toastError('Please input valid premium amount');
          return;
        }
        let allocationFunds = res.map(el => el.num).reduce((a, b) => a + Number(b), 0);
    
        if (allocationFunds !== 100) {
          toastError('Allocations should add up to 100%');
    
          return;
        }
        const pricesForFunds = Object.keys(transactionInfo.value)
        .map((key) => ({
          [key]: prices[Number(key)]['bid_price']
        }))
        .reduce((acc, obj) => Object.assign(acc, obj), {});
        try {
          const res = await reducePremium(product.ID, premiumType, premiumAmt);

          toastSuccess('Transaction is successful!');

          refresh();
        } catch (err) {
          toastError(err);
        }
        break;
      }

      case 5: {
        if (!checkFunds(res)) return;
        let allocationFunds = res.map(el => el.num).reduce((a, b) => a + Number(b), 0);
    
        if (allocationFunds !== 100) {
          toastError('Allocations should add up to 100%');
    
          return;
        }

        const funds = formatFunds(res);
        const filteredTransactionInfo = Object.keys(transactionInfo).reduce((acc, key) => {
          acc[key] = Object.keys(transactionInfo[key])
            .filter((fundId) => funds.includes(Number(fundId)))
            .reduce((obj, fundId) => {
              obj[fundId] = transactionInfo[key][fundId];
              return obj;
            }, {});
          return acc;
        }, {});

        const pricesForFunds = Object.keys(transactionInfo.value)
        .map((key) => ({
          [key]: prices[Number(key)]['bid_price']
        }))
        .reduce((acc, obj) => Object.assign(acc, obj), {});

        try {
          if (date > new Date()) {
            toastError('Invalid date');
            return;
          }
          const result2 = await fundswitch(
            product.ID,
            { 1: premiumAmt },
            filteredTransactionInfo.value,
            dateToString(date),
            filteredTransactionInfo.value,
            filteredTransactionInfo.units,
            filteredTransactionInfo.balanceUnits,
            pricesForFunds,
            'Deposit'
          ).then(() => {
            toastSuccess('Transaction is successful!');
            refresh();
          });
        } catch (err) {
          console.error('Error:', err);
          toastError(err);
        }
        break;
      }

      default:
        break;
    }
  };

  const apportionmentArray = (dic) => {
    const arr = [];
    if (!dic.premium) {
      return arr;
    }
    Object.keys(dic.premium.apportionment).forEach(function (key) {
      arr.push({
        id: key,
        pct: dic.premium.apportionment[key]
      });
    });
    return arr;
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFundIds();
      setFundOptions(res);

      const prices = await getFundsPriceLatest();
      setFundPrices(prices);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredFundOptions(filterOptions(fundOptions));
  }, [tableData, fundOptions]);

  const selectedIds = funds.map((fund) => +fund.id);
  const filteredOptions = fundOptions.filter((option) => !selectedIds.includes(option));

  useEffect(() => {
    setFilteredFundBOptions(filteredOptions);
  }, [funds, fundOptions]);

  const getTransactionName = () => {
    if (transactionType == 1) return 'Fund Switch';
    else if (transactionType == 2) return 'ILP Withdrawal';
    else if (transactionType == 3) return 'Premium Apportionment';
    else if (transactionType == 4) return 'Reduction in Premium / Recurrent Single Premium';
    else if (transactionType == 5) return 'Single Premium Top-Up';
    else return '';
  };

  const calculateFundsUnits = () => {
    let units = [];
    let unitsB = [];

    for (let i = 0; i < fundsB.length; i++) {
      unitsB.push({
        id: fundsB[i].id,
        num: isNaN(fundsB[i].num) ? 0 : fundsB[i].num
      });
    }

    for (let i = 0; i < funds.length; i++) {
      units.push({
        id: funds[i].id,
        num: isNaN(funds[i].num) ? 0 : funds[i].num
      });
    }

    return [units, unitsB];
  };

  calculateFundsUnits();

  const getTransactionComponent = () => {
    if (transactionType == 1) {
      return (
        <Box>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Switch Out
          </Text>
          <Card
            direction="column"
            w="100%"
            mb="16px"
            border={'1px solid'}
            borderColor={borderColor}
          >
            <UnitsAddition
              isAllocation={false}
              includeUnits={true}
              fundOptions={filteredFundOptions}
              funds={funds}
              fundPrices={fundPrices}
              balances={product.balances}
              addFund={addFilteredFund}
              removeFund={removeFund}
              editFundId={editFundId}
              editFundNum={editFundNum}
              date={date}
              type="switchOut"
              changer={changer}
              transactionType={transactionType}
              setTransactionInfo={setTransactionInfo}
              units={calculateFundsUnits()}
              transactionInfo={transactionInfo}
            />
          </Card>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Switch In
          </Text>
          <Card direction="column" w="100%" border={'1px solid'} borderColor={borderColor}>
            <UnitsAddition
              isAllocation={true}
              includeUnits={true}
              fundOptions={filteredFundBOptions}
              funds={fundsB}
              fundPrices={fundPrices}
              balances={product.balances}
              addFund={addFundB}
              removeFund={removeFundB}
              editFundId={editFundIdB}
              editFundNum={editFundNumB}
              units={calculateFundsUnits()}
              type="switchIn"
              date={date}
              changer={changer}
              setTransactionInfo={setTransactionInfo}
              transactionInfo={transactionInfo}
              allocation="Allocation"
            />
          </Card>
        </Box>
      );
    }
    if (transactionType == 2) {
      return (
        <Box>
          <Text color={textColor} fontSize="18px" fontWeight="700" mb="12px">
            Withdraw
          </Text>
          <Card
            direction="column"
            w="100%"
            mb="16px"
            border={'1px solid'}
            borderColor={borderColor}
          >
            <UnitsAddition
              isAllocation={false}
              includeUnits={true}
              fundOptions={filteredFundOptions}
              funds={funds}
              fundPrices={fundPrices}
              balances={product.balances ? product.balances : {}}
              addFund={addFilteredFund}
              removeFund={removeFund}
              editFundId={editFundId}
              editFundNum={editFundNum}
              bgButton={bgButton}
              date={date}
              type="withdraw"
              units={calculateFundsUnits()}
              setTransactionInfo={setTransactionInfo}
            />
          </Card>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Withdrawal Value
          </Text>
          <Card direction="column" w="100%">
            Amount: ${getWithdrawalValue()}
          </Card>
        </Box>
      );
    }
    if (transactionType == 4) {
      return (
        <Box>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Current Premium
          </Text>
          <Card direction="column" w="100%" border={'1px solid'} borderColor={borderColor}>
            <PremiumEdit
              premium={product.premium ? product.premium : { frequency: 'anually', amount: 0 }}
              productName={product.name}
              premiumDate={formatDate(product.CreatedAt)}
              premiumAmt={premiumAmt}
              handlePremium={handlePremium}
              handlePremiumType={handlePremiumType}
              date={date}
            />
          </Card>
        </Box>
      );
    }
    if (transactionType == 3) {
      return (
        <Box>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Current Premium Apportionment
          </Text>
          <Card direction="column" w="100%" mb="16px">
            <FundAllocationCard apportionment={apportionmentArray(product)} />
          </Card>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Premium Apportionment
          </Text>
          <Card direction="column" w="100%">
            <UnitsAddition
              isAllocation={true}
              includeUnits={false}
              fundOptions={fundOptions}
              funds={funds}
              fundPrices={fundPrices}
              balances={product.balances ? product.balances : {}}
              addFund={addFund}
              removeFund={removeFund}
              editFundId={editFundId}
              editFundNum={editFundNum}
              date={date}
              transactionType={transactionType}
              setTransactionInfo={setTransactionInfo}
              units={calculateFundsUnits()}
              type="single-top-up"
              allocation="Allocation"
            />
          </Card>
        </Box>
      );
    }
    if (transactionType == 5) {
      return (
        <Box>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Top-up Value
          </Text>
          <Card
            direction="column"
            w="100%"
            mb="16px"
            border={'1px solid'}
            borderColor={borderColor}
          >
            <Text fontWeight="600" marginBottom=".5rem">
              Amount ($)
            </Text>
            <Input
              width={'250px'}
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder="e.g. 111"
              onChange={handlePremium}
              value={premiumAmt}
            />
          </Card>
          <Text color={textColor} fontSize="18px" fontWeight="700" lineHeight="100%" mb="12px">
            Top-Up
          </Text>
          <Card direction="column" w="100%" border={'1px solid'} borderColor={borderColor}>
            <UnitsAddition
              date={date}
              isAllocation={true}
              includeUnits={true}
              fundOptions={fundOptions}
              funds={funds}
              fundPrices={fundPrices}
              balances={product.balances ? product.balances : {}}
              addFund={addFund}
              removeFund={removeFund}
              editFundId={editFundId}
              editFundNum={editFundNum}
              transactionType={transactionType}
              premiumAmt={premiumAmt}
              units={calculateFundsUnits()}
              setTransactionInfo={setTransactionInfo}
              type="top-up"
              allocation="Allocation"
            />
          </Card>
        </Box>
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxW="100%" bg={cardColor} border="1px solid" borderColor={borderColor}>
        <ModalHeader>{getTransactionName(transactionType)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(3, 1fr)" gap="20px" mb="20px">
            <GridItem colSpan={2}>
              <FundsOverview 
               fundsData={fundsData}
               transactions={transactions}
               showWelcomeBonus={showWelcomeBonus}
               todaysPrices={todaysPrices}
              />
            </GridItem>
            <GridItem colSpan={1}>
              <SummaryPieCard
                piechartData={piechartData}
                piechartOptions={piechartOptions}
                vertical={false}
                hasDetails={false}
              />
            </GridItem>
          </Grid>

          <Grid templateColumns="repeat(3, 1fr)" gap="20px" mb="20px">
            <GridItem colSpan={1}>
              <MiniCalendar
                h="100%"
                minW="100%"
                selectRange={false}
                date={date}
                onDateChange={setDate}
              />
            </GridItem>
            <GridItem colSpan={2}>{getTransactionComponent()}</GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button variant="outline" onClick={handleClose} mr="8px">
              Close
            </Button>
            <Button bg={bgButton} _hover={bgHover} color={buttonTextColor} onClick={handleSubmit}>
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
