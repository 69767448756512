// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';

import React from 'react';
import ImportClientForm from './components/ImportClientForm';

export default function ImportClientProfile() {
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '0px' }}>
        <ImportClientForm />
      </SimpleGrid>
    </Box>
  );
}
