import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { getFundCode } from 'service/api';
import { formatNumber, getRoiFund } from 'utils/numbers';

const FundsOverview = ({
  fundsData,
  transactions,
  showWelcomeBonus,
  todaysPrices
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (Array.isArray(fundsData)) {
      setTableData(fundsData);
    } else {
      setTableData([]);
    }
  }, [fundsData]);

  let roi = 0;
  let totalInvestmentAmount = 0;
  let totalInvestmentValue = 0;

  if (transactions.length > 0) {
    totalInvestmentAmount = transactions.reduce((acc, d) => {
      if (showWelcomeBonus) {
        if (
          d.applicationType === 'Net Investment Premium' ||
          d.applicationType === 'Welcome Bonus' || 
          d.applicationType === 'Premium Top-up' || 
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Deposit'
        ) {
          return acc + Number(d.amount);
        } else if (d.applicationType === 'Switch Out' || d.applicationType === 'ILP Withdrawal') {
          return acc - Math.abs(Number(d.amount));
        }
      } else {
        if (d.applicationType === 'Net Investment Premium') {
          return acc + Number(d.amount);
        }
      }
      return acc;
    }, 0);

    totalInvestmentValue = transactions.reduce((acc, d) => {
        let price = 0;
        if (d.type !== 'manual') {
          price = todaysPrices.find((el) => {
            return Number(getFundCode(el['fund_id'])) === d.fundCode;
          });
        } else {
          const id = d.fundId;
          const prices = todaysPrices.find((el) => {
            return Number(getFundCode(el['fund_id'])) === Number(getFundCode(id.trim()));
          });
          price = prices;
        }
  
        if (d.applicationType === 'Switch Out' || d.applicationType === 'ILP Withdrawal' || d.applicationType === 'Policy Fee' || d.applicationType === "Police Fees") {
          let units = 0;
          if(d.type === 'manual') {
            units = d.transactionInfo.unit 
          } else {
            units = d.units
          }
          return acc - Number(price['bid_price']) * Number(units);
        } else if (d.applicationType === 'Welcome Bonus'|| d.applicationType === 'Net Investment Premium' || d.applicationType === 'Premium Top-up' || d.applicationType === 'Switch In' || d.applicationType === 'Deposit' || d.applicationType === 'Premium Payment') {
          let units = 0;
          if(d.type === 'manual') {
            units = d.transactionInfo.unit
          } else {
            units = d.units
          }
          return acc + Number(price['bid_price']) * Number(units);
        } else {
        return acc;
        } 
      }, 0);

}

     roi = getRoiFund(totalInvestmentAmount, totalInvestmentValue);


  const columns = React.useMemo(
    () => [
      { Header: 'NO', accessor: 'no' },
      { Header: 'FUND', accessor: 'asset' },
      { Header: 'START DATE', accessor: 'start_date' },
      { Header: 'UNITS', accessor: 'units' },
      { Header: 'CURRENT VALUE', accessor: 'value' },
      { Header: 'CURRENT PRICE', accessor: 'current_price' },
      { Header: 'ROI', accessor: 'roi' },
      { Header: 'AVERAGE PRICE', accessor: 'average_price' }
    ],
    [tableData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: tableData || [],
    columns,
    debug: true
  });

  const borderColorCard = useColorModeValue('#162744', '#DFDFDF');
  const textColor = useColorModeValue('black', 'white');

  return (
    <Box w="100%" py="20px" borderRadius="20px" border={`1px solid ${borderColorCard}`}>
      <Text fontSize="22px" fontWeight="700" lineHeight="100%" mb="5px" ml="20px">
        Funds Overview
      </Text>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  style={{
                    padding: '10px',
                    borderBottom: '1px solid #A0AEBF',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '13px'
                  }}
                >
                  <Text color={textColor}>{column.render('Header')}</Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td
                    key={index}
                    {...cell.getCellProps()}
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      fontSize: '14px',
                      fontWeight: 'bold'
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box px="20px" fontSize="18px" mt="25px">
        <Box mb="4px" display="flex" justifyContent="space-between" flexDirection="row">
          <Text>Total Investment Value:</Text>
          <Text>{totalInvestmentValue ? `$${formatNumber(totalInvestmentValue)}` : 'Loading'}</Text>
        </Box>
        <Box mb="4px" display="flex" justifyContent="space-between" flexDirection="row">
          <Text>Total Invested Amount:</Text>
          <Text>${formatNumber(totalInvestmentAmount)}</Text>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="row">
          <Text>Return on Investment (ROI): </Text>
          <Text>{totalInvestmentValue ? `${roi.toFixed(2)}%` : 'Loading'}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default FundsOverview;
