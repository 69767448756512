import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Flex,
    useColorModeValue
  } from '@chakra-ui/react';
  
  import React from 'react';
  
  export default function NotifyModal(props) {
    const { isOpen, onClose, message } = props;
  
    const cardColor = useColorModeValue('white', 'navy.900');
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="88rem" bg={cardColor}>
          <ModalHeader>Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{message}</ModalBody>
        </ModalContent>
      </Modal>
    );
  }
  