/* eslint-disable no-inner-declarations */
import { Box, useColorModeValue } from '@chakra-ui/react';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  LinearScale
} from 'chart.js';

import { Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { dateToString } from 'service/api';
import Breakdown from './Breakdown';

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.defaults.font.size = 15;
ChartJS.defaults.font.family = 'Arial';
ChartJS.defaults.font.weight = 'normal';
ChartJS.defaults.color = 'white';

function addMonths(date, months) {
  let temp = new Date(date);
  temp.setMonth(temp.getMonth() + months);
  if (temp.getDate() != date.getDate()) temp.setDate(0);
  return temp;
}

function searchForPrice(fundPrices, date) {
  const res = fundPrices.find((fundPrice) => {
    return fundPrice['valueDate'].indexOf(dateToString(date)) !== -1;
  });
  if (res !== undefined) {
    return res['bidPrice'];
  } else {
    toastError('Fund Price not found!');
    return 1;
  }
}

export default function LineChart(props) {
  const bgBlock = useColorModeValue('transparent', 'transparent');
  const textColor = useColorModeValue('black', 'white');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const { iproducts, fundPrices } = props;

  const options = {
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: textColor
        }
      },
      title: {
        display: true,
        text: 'Product(s) Performance',
        color: textColor
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.chart.data.labels[context.dataIndex];
            return `Value: ${context.raw.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })} (${label})`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: textColor,
          autoSkip: false,
          maxRotation: 0, // Ensure labels are horizontal
          minRotation: 0, // Ensure labels are horizontal
          callback: function (value, index) {
            // Use index to get the label directly
            const label = this.getLabelForValue(index); // Retrieve the label
            const year = label.split(' ')[1]; // Extract year from the label
            return index % 12 === 0 ? year : ''; // Show year only for the first month of each year
          },
          font: {
            size: 12
          }
        },
        title: {
          display: true,
          text: 'Years',
          color: textColor
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          color: textColor
        },
        title: {
          display: true,
          text: 'Value',
          color: textColor
        }
      }
    }
  };

  const [chartData, setChartData] = useState(null);
  const [startingYear, setStartingYear] = useState(0);
  const [startingMonth, setStartingMonth] = useState(0);
  const [endingYear, setEndingYear] = useState(0);

  const generateLabels = (start, end) => {
    const labels = [];
    let startDate = new Date(start);
    let endDate = new Date(end);

    while (startDate <= endDate) {
      labels.push(`${months[startDate.getMonth()]} ${startDate.getFullYear()}`);
      startDate.setMonth(startDate.getMonth() + 1); // Increment month
    }
    return labels;
  };

  const buyAtGivenDate = (investment, apportionment, portfolio, fundPrices, date) => {
    apportionment.forEach((ele) => {
      const proportion = ele.num / 100.0;
      const proportionValue = proportion * investment;
      const fundId = parseInt(ele.id);
      const price = searchForPrice(fundPrices[fundId], date);
      portfolio[fundId] += proportionValue / price;
    });

    return portfolio;
  };

  const computeProductWorth = (portfolio, fundPrices, date) => {
    const networth = Object.keys(portfolio).reduce((acc, fundId) => {
      const price = searchForPrice(fundPrices[fundId], date);
      return acc + price * portfolio[fundId];
    }, 0.0);

    return networth;
  };

  const generateDatapoints = (iproduct, fundPrices, start, end) => {
    const productWorth = [];
    let startDate = new Date(start);
    let endDate = new Date(end);

    const frequency = iproduct.frequency;
    const annualInvestment = parseFloat(iproduct.annualInvestment);
    const apportionment = iproduct.apportionment;

    let annualMonth = startDate.getMonth();
    let lumpsumflag = false;
    const monthlyInvestment = frequency === 'monthly' ? annualInvestment / 12 : 0;

    let portfolio = {};
    Object.keys(fundPrices).forEach(function (key) {
      portfolio[key] = 0.0;
    });

    while (startDate <= endDate) {
      const currMonth = startDate.getMonth();

      if (frequency === 'monthly') {
        portfolio = buyAtGivenDate(
          monthlyInvestment,
          apportionment,
          portfolio,
          fundPrices,
          startDate
        );
      } else if (frequency === 'annually' && currMonth === annualMonth) {
        portfolio = buyAtGivenDate(
          annualInvestment,
          apportionment,
          portfolio,
          fundPrices,
          startDate
        );
      } else if (!lumpsumflag && frequency === 'lump-sum') {
        portfolio = buyAtGivenDate(
          annualInvestment,
          apportionment,
          portfolio,
          fundPrices,
          startDate
        );
        lumpsumflag = true;
      } else if (frequency !== 'monthly' && frequency !== 'annually' && frequency !== 'lump-sum') {
        toastError('Unknown frequency type: ' + frequency);
      }

      const worth = computeProductWorth(portfolio, fundPrices, startDate);
      productWorth.push(worth);
      startDate = addMonths(startDate, 1);
    }

    return productWorth;
  };

  useEffect(() => {
    const plotGraph = async () => {
      const start = iproducts[0].startDate;
      const end = iproducts[0].endDate;

      setStartingYear(new Date(start).getFullYear());
      setStartingMonth(new Date(start).getMonth());
      setEndingYear(new Date(end).getFullYear());

      const labels = generateLabels(start, end);
      const colors = ['#4318FF', '#39B8FF', 'white'];
      const datasets = [];
      for (let i = 0; i < iproducts.length; i++) {
        const datapoints = generateDatapoints(iproducts[i], fundPrices, start, end);
        datasets.push({
          label: 'Product #' + (i + 1).toString(10),
          data: datapoints,
          backgroundColor: colors[i],
          borderColor: colors[i],
          pointBorderColor: textColor,
          pointBorderWidth: 1,
          borderWidth: 1,
          radius: 1.5,
          tension: 0.5
        });
      }
      setChartData({
        labels: labels,
        datasets: datasets
      });
    };

    plotGraph();
  }, [fundPrices, textColor]);

  return (
    <div style={{ maxWidth: 'inherit' }}>
      <Box
        pt={{ base: '130px', md: '80px', xl: '10px' }}
        pl={{ base: '20px', md: '40px', xl: '10px' }}
        bg={bgBlock}
        borderRadius={'20px'}
        height={'auto'}
        marginBottom={'20px'}
        border={`1px solid ${borderColor}`}
        overflowX="hidden"
      >
        {chartData !== null ? (
          <div style={{ maxWidth: '95%', overflowX: 'auto' }}>
            <div
              className="container"
              style={{
                whiteSpace: 'nowrap',
                minWidth: `${endingYear - startingYear > 5 ? '1100px' : '950px'}`,
                maxHeight: '600px'
              }}
            >
              <Line data={chartData} options={options} style={{}}></Line>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spinner
              size="xl"
              color="teal"
              emptyColor="gray.200"
              thickness="4px"
              speed="0.65s"
              margin={'10px'}
            />
          </div>
        )}
      </Box>
      {chartData !== null ? (
        <Breakdown iproducts={iproducts} chartData={chartData}></Breakdown>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}></div>
      )}
    </div>
  );
}
