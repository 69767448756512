import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useColorModeValue
} from '@chakra-ui/react';

export default function StartTransactionModal(props) {
  const { isOpen, onClose, selectTransaction } = props;
  const modalColor = useColorModeValue('#DFDFDF', '#162744');

  const handleClick = (id) => {
    onClose();
    selectTransaction(id);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="48rem" bg={modalColor}>
        <ModalHeader>Choose Transaction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent="center" alignItems="center" direction="column">
            <Button colorScheme="blue" mb="16px" width="100%" onClick={() => handleClick(1)}>
              FUND SWITCH
            </Button>
            <Button colorScheme="red" mb="16px" width="100%" onClick={() => handleClick(2)}>
              ILP WITHDRAWAL
            </Button>
            <Button colorScheme="orange" mb="16px" width="100%" onClick={() => handleClick(3)}>
              PREMIUM APPORTIONMENT
            </Button>
            <Button colorScheme="purple" mb="16px" width="100%" onClick={() => handleClick(4)}>
              REDUCTION IN PREMIUM / RECURRENT SINGLE PREMIUM
            </Button>
            <Button colorScheme="green" mb="16px" width="100%" onClick={() => handleClick(5)}>
              SINGLE PREMIUM TOP-UP
            </Button>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center">
            <Button variant="outline" onClick={onClose}>
              Close
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
