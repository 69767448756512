import { Box, Button, Flex, Input, Select, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { formatFundName, getFundsPricesByDay } from 'service/api';
import { getStrictDate } from 'utils/string';

export default function UnitsAddition(props) {
  const {
    transactionType,
    isAllocation,
    includeUnits,
    fundOptions,
    funds,
    removeFund,
    addFund,
    editFundId,
    editFundNum,
    balances,
    type,
    units,
    date,
    changer,
    premiumAmt,
    setTransactionInfo,
    allocation,
    transactionInfo
  } = props;

  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const inputBg = useColorModeValue('whiteAlpha.300', 'whiteAlpha.100');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const [values, setValues] = useState({});
  const [calculatedUnits, setCalculatedUnits] = useState({});
  const [prices, setPrices] = useState([]);
  const [totalAllocation, setTotalAllocation] = useState(0);

  const fetchPrices = async () => {
    try {
      const price = await getFundsPricesByDay(getStrictDate(date));
      setPrices(price);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, [funds, date]);

  useEffect(() => {
    funds.forEach((fund) => {
      const value = getValue(fund, type);
      const balance = getBalance(fund, type);
      // if (value !== undefined) {
        // if (
        //   type === 'switchIn' ||
        //   type === 'switchOut' ||
        //   type === 'withdraw' ||
        //   type === 'top-up'
        // ) {
        //   setTransactionInfo((prevValues) => ({
        //     ...prevValues,
        //     ['value']: {
        //       ...prevValues.value,
        //       [fund.id]: +value
        //     }
        //   }));
        // }

        // setValues((prevValues) => ({
        //   ...prevValues,
        //   [fund.id]: value
        // }));
      // }

      if (balance !== undefined) {
        const price = prices.find((p) => p['fund_id'] === fund.id);
        // setCalculatedUnits((prevValues) => ({
        //   ...prevValues,
        //   [fund.id]: balance
        // }));  
        if(type !== 'switchIn'){
          setValues((prevValues) => ({
            ...prevValues,
            [fund.id]: (balance * price['bid_price']).toFixed(2)
          }))
        }
      }
    });
  }, [funds, type, prices, changer, premiumAmt]);

  const getValue = (value, type) => {
    const id = value.id;
    const price = (id) => prices.find((p) => p['fund_id'] === id);
    let calculatedValue = 0;
    const balance = balances[id] || 0;

    if (type === 'switchIn') {
      const unitsFlatted = units[0].flat();
      const unitsFlattedB = units[1].flat();
      const findedValueB = unitsFlattedB.find((e) => parseInt(e.id) === parseInt(id));

      const arrayUnits2 = unitsFlatted
        .map((el) => {
          return el.num
        })
        .reduce((a, b) => a + (Number(b) / 100) * findedValueB.num, 0);

      setValues((prevValues) => ({
        ...prevValues, 
        [value.id]: (arrayUnits2 + (balance * price(id)['bid_price'])).toFixed(2)
      })) 
      setCalculatedUnits((prevValues) => ({
        ...prevValues,
        [value.id]: (arrayUnits2 / price(id)['bid_price']).toFixed(2)
      }))
    } else if (transactionType === 5) {
      const unitsFlatted = units[0].flat();
      const findedValue = unitsFlatted.find((e) => parseInt(e.id) === parseInt(id));
      const calculated = (findedValue.num / 100) * premiumAmt;
      calculatedValue = calculated.toFixed(2);
    } else {
      const units = value.num;
      if (units) {
        let currentFundPrice = price(value.id)['bid_price'];
        calculatedValue = (Number(units) / currentFundPrice).toFixed(2);
        setCalculatedUnits((prevValues) => ({
          ...prevValues,
          [value.id]: calculatedValue
        }))
      }
    }

    return calculatedValue;
  };
  const getBalance = (value, type) => {
    const id = value.id;
    let calculatedValue = 0;
    const price = prices.find((p) => p.fund_id === id);

    if (type === 'switchIn') {
      let balance = balances[id] ? balances[id] : 0;
      // const unitsFlattedB = units[1].flat();
      // const findedValueB =
      //   Number(unitsFlattedB.find((e) => parseInt(e.id) === parseInt(id)).num) / 100;
      let result = Number(values[id]) / price['bid_price'];

      calculatedValue = result.toFixed(2);
      setTransactionInfo((prevValues) => ({
        ...prevValues,
        ['balanceUnits']: {
          ...prevValues.balanceUnits,
          [id]: balance + result
        },
        ['units']: {
          ...prevValues.units,
          [id]: result
        },
        ['value']: {
          ...prevValues.value,
          [id]: result*price['bid_price']
        }
      }));
    } else if (transactionType === 5) {
      let balance = balances[id] ? balances[id] : 0;
      const unitsFlatted = units[0].flat();
      const findedValue = unitsFlatted.find((e) => parseInt(e.id) === parseInt(id));
      const calculated = (findedValue.num / 100) * premiumAmt;
      const result = calculated / price['bid_price'];
      setTransactionInfo((prevValues) => ({
        ...prevValues,
        ['balanceUnits']: {
          ...prevValues.balanceUnits,
          [id]: balance + result
        },
        ['units']: {
          ...prevValues.units,
          [id]: result
        }
      }));
      setCalculatedUnits((prevValues) => ({
        ...prevValues,
        [id]: result.toFixed(2)
      }))
      calculatedValue = result.toFixed(2);
    } else {
      const balance = balances[id];
      if (type === 'switchOut' || type === 'withdraw' || type === 'top-up') {
        const unitsFlatted = units[0].flat();
        setTransactionInfo((prevValues) => ({
          ...prevValues,
          ['balanceUnits']: {
            ...prevValues.balanceUnits,
            [id]: (balance* price['bid_price']) - +unitsFlatted[0].num
          },
          ['value']: {
            ...prevValues.units,
            [id]: +unitsFlatted[0].num
          },
          ['units']: {
            ...prevValues.units,
            [id]: +unitsFlatted[0].num / price['bid_price']
          }
        }));
      }
      if (balance) {
        calculatedValue = parseFloat(balance).toFixed(2);
      } else {
        return 0;
      }
    }
    return calculatedValue;
  };

  const filterFundOptions = (currentId) => {
    const ids = funds.map((t) => parseInt(t.id));
    return fundOptions.filter((o) => parseInt(o) === currentId || !ids.includes(parseInt(o)));
  };

  useEffect(() => {
    const total = funds.reduce((sum, fund) => {
      return sum + (Number(fund.num) || 0);
    }, 0);
    setTotalAllocation(total);
  }, [funds]);

  const handleInputChange = (index, inputValue, valueId) => {
    let numericValue = Number(inputValue);
    const isValidFormat = /^\d*\.?\d{0,2}$/.test(inputValue);
    let resetState = false; // Tracks if we’ve reset to max balance
    const tolerance = 0.01; 
    
    if (isAllocation) {
      if (!isValidFormat || numericValue > 100) return;
    } else {
      const price = prices.find((p) => p['fund_id'] === valueId);
      let maxBalance = balances[valueId] * price['bid_price'];
      
      // Skip the evaluation if resetting
      if (Math.abs(numericValue - maxBalance) < tolerance && !resetState) return;
      
      if (numericValue > maxBalance) {
        editFundNum(index, String(maxBalance.toFixed(2)));
        resetState = true; // Set flag after applying max balance
        return;
      }
    }
  
    // Only update the input if it's a valid format and within the limits
    editFundNum(index, String(inputValue));
  };
  

  const isValidNumber = (value) => {
    return value === '' || /^\d*\.?\d{0,2}$/.test(value);
  };

  return (
    <Card
      direction="column"
      w="100%"
      borderRadius="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      padding="1px"
    >
      <Flex direction="column">
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          spacing={10}
          fontWeight="600"
        >
          <Box width="300px">Fund</Box>
          <Box width="250px">
            {isAllocation ? `Allocation (Total: ${totalAllocation}%)` : 'Value'}
          </Box>
          {includeUnits && <Box width="120px">{isAllocation ? 'Value' : 'Remaining Value'}</Box>}
          {includeUnits && <Box width="120px">Units</Box>}
        </Box>
        {funds.map((value, index) => (
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            gap="20px"
            key={index}
            my="6px"
          >
            <Select
              width="300px"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              value={Number(value.id)}
              onChange={(e) => editFundId(index, Number(e.target.value))}
              border="1px solid"
              borderColor={borderColor}
            >
              {filterFundOptions(value.id).map((fundOption) => (
                <option value={Number(fundOption)} key={fundOption}>
                  {formatFundName(fundOption)}
                </option>
              ))}
            </Select>
            <Input
                fontSize="sm"
                width="250px"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder={isAllocation ? 'e.g. % of Allocation' : 'e.g. No. of Value'}
                value={value.num}
                onChange={(e) => handleInputChange(index, e.target.value, value.id)}
                isInvalid={isAllocation && value.num > 100 || !isValidNumber(value.num)}
                border="1px solid"
                borderColor={isAllocation ?
                  (value.num > 100 ? 'red.500' : borderColor) : borderColor
                }
              />
            {includeUnits && (
              <Flex width="120px" align="center">
                {values[value.id] ? values[value.id] : 0}
              </Flex>
            )}
            {includeUnits && (
              <Flex width="120px" align="center">
              {calculatedUnits[value.id] ? calculatedUnits[value.id] : 0}
              </Flex>
            )}
          </Box>
        ))}
      </Flex>
      <Flex mt="25px" justify="center" width="100%">
        <Button
          fontSize="16px"
          mx="8px"
          bg={bgButton}
          _hover={bgHover}
          onClick={addFund}
          disabled={
            funds.length >= fundOptions.length || (type === 'switchIn' && units[0].length === 0)
          }
          color={buttonTextColor}
        >
          +
        </Button>
        <Button
          variant="outline"
          fontSize="16px"
          mx="8px"
          onClick={removeFund}
          disabled={funds.length <= 0}
        >
          -
        </Button>
      </Flex>
    </Card>
  );
}
