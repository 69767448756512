import { numberWithCommas } from 'utils/numbers';

export const defaultPiechartData = {
  options: {
    labels: [],
    colors: [],
    chart: {
      width: '50px'
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    stroke: {
      show: false
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    hover: {
      mode: null
    },
    plotOptions: {
      donut: {
        size: 150,
        expandOnClick: true,
        donut: {
          labels: {
            show: true
          }
        }
      }
    },
    fill: {
      colors: []
    }
  },
  data: []
};

export const fillPiechartData = (labels, colors) => {
  return {
    labels: labels,
    colors: colors,
    chart: {
      width: '50px'
    },
    states: {
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    stroke: {
      show: false
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false
          }
        }
      }
    },
    fill: {
      colors: colors
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          w['config']['labels'][seriesIndex] +
          ' $' +
          numberWithCommas(series[seriesIndex].toFixed(2))
        );
      }
    }
  };
};
