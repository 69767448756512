// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';

import React from 'react';
import ChangePasswordForm from './components/ChangePasswordForm';
import EditProfileForm from './components/EditProfileForm';

export default function ProfileSettings() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <EditProfileForm />
      <ChangePasswordForm />
    </Box>
  );
}
