import { Flex, useColorModeValue } from '@chakra-ui/react';

import logoDark from 'assets/img/auth/logo-dark.png';
import logoLight from 'assets/img/auth/logo-light.png';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  const logo = useColorModeValue(logoLight, logoDark);
  return (
    <Flex align="center" direction="column">
      <img src={logo} alt="logo" width="200px" height="200px" />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
