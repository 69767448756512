export const columnsWhitelist = [
  {
    Header: 'EMAIL',
    accessor: 'email'
  },
  {
    Header: 'STATUS',
    accessor: 'status'
  }
  // Uncomment this to enable remove whitelist feature
  /*,
  {
    Header: 'DELETE',
    accessor: 'delete'
  }
  */
];
