import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';

import { css } from '@emotion/react';
import Card from 'components/card/Card';
import { useMemo } from 'react';
import { MdCancel, MdCheckCircle } from 'react-icons/md';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { formatNumber, formatPrice, limitNumber, numberWithCommas } from 'utils/numbers';

const hiddenColumnStyle = css`
  display: none;
`;

export default function BasicTableWithPagination(props) {
  const {
    roiByFund,
    totalInvestmentValueByFund,
    totalInvestmentAmountByFund,
    columnsData,
    tableData,
    title,
    type
  } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  let data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = tableInstance;

  const textColor = useColorModeValue('black', 'white');
  const borderColor = useColorModeValue('#A0AEBF', '#A0AEBF');
  const linkColor = useColorModeValue('#47abff', '#47ABFF');
  const borderColorCard = useColorModeValue('#162744', '#DFDFDF');

  const shouldCenter = () => {
    return true;
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      justifyContent="space-between"
      border={`1px solid ${borderColorCard}`}
    >
      <Box>
        {title.length > 0 && (
          <Flex px="20px" justify="space-between" align="center">
            <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
              {title}
            </Text>
          </Flex>
        )}

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="0px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map(
                  (column, index) =>
                    column.render('Header') !== 'CLIENT ID' && (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="10px"
                        ps={shouldCenter(column.render('Header')) ? '10px' : '24px'}
                        key={index}
                        borderColor={borderColor}
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color={textColor}
                          display="block"
                          textAlign={shouldCenter(column.render('Header')) ? 'center' : 'left'}
                        >
                          {column.render('Header')}
                        </Flex>
                      </Th>
                    )
                )}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()} id={page.length === 3 ? 'testing' : ''}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = '';
                    let isNavLink = false;
                    if (
                      cell.column.Header === 'NAME' ||
                      cell.column.Header === 'ID' ||
                      cell.column.Header === 'TYPE' ||
                      cell.column.Header === 'EMAIL' ||
                      cell.column.Header === 'CLIENT'
                    ) {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                          {cell.value}
                        </Text>
                      );
                    } else if (
                      cell.column.Header === 'TOTAL INVESTED' ||
                      cell.column.Header === 'AUM' ||
                      cell.column.Header === 'TOTAL INVESTMENT' ||
                      cell.column.Header === 'UNREALISED PROFIT/LOSS' ||
                      cell.column.Header === 'VALUE' ||
                      cell.column.Header === 'CURRENT VALUE'
                    ) {
                      if (cell.value >= 0) {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                            ${numberWithCommas(cell.value)}
                          </Text>
                        );
                      } else {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                            -${numberWithCommas(Math.abs(cell.value))}
                          </Text>
                        );
                      }
                    } else if (
                      cell.column.Header === 'CURRENT PRICE' ||
                      cell.column.Header === 'PRICE'
                    ) {
                      if (cell.value > 0) {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                            ${formatPrice(cell.value)}
                          </Text>
                        );
                      } else {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                            -${formatPrice(Math.abs(cell.value))}
                          </Text>
                        );
                      }
                    } else if (cell.column.Header === 'UNITS') {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                          {numberWithCommas(cell.value)}
                        </Text>
                      );
                    } else if (cell.column.Header === 'CLIENT NAME') {
                      const filtered = cell.row.allCells.filter(
                        (o) => o.column.Header === 'CLIENT ID'
                      );
                      if (filtered.length === 1) {
                        data = (
                          <Box textAlign="center">
                            <NavLink
                              to={`/agent/client/${filtered[0].value}`}
                              color={textColor}
                              fontSize="sm"
                              fontWeight="700"
                              textDecoration="underline"
                              textAlign="center"
                            >
                              {cell.value}
                            </NavLink>
                          </Box>
                        );
                        isNavLink = true;
                      } else {
                        data = (
                          <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                            ${cell.value}
                          </Text>
                        );
                      }
                    } else if (
                      cell.column.Header === 'XIRR' ||
                      cell.column.Header === 'ROI' ||
                      cell.column.Header === 'Overall XIRR' ||
                      cell.column.Header === 'Overall ROI'
                    ) {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                          {numberWithCommas(limitNumber(cell.value))}%
                        </Text>
                      );
                    } else if (cell.column.Header === 'STATUS') {
                      data = (
                        <Box textAlign="center">
                          <Icon
                            w="24px"
                            h="24px"
                            me="5px"
                            color={cell.value ? 'green.500' : 'red.500'}
                            as={cell.value ? MdCheckCircle : MdCancel}
                            textAlign="center"
                          />
                        </Box>
                      );
                    } else {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700" textAlign="center">
                          {cell.value}
                        </Text>
                      );
                    }
                    //Text Color of Navlink in Tables
                    if (isNavLink) {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: '14px' }}
                          textDecorationLine="underline"
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor="transparent"
                          fontWeight="700"
                          color={linkColor}
                        >
                          {data}
                        </Td>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                        css={cell.column.Header === 'CLIENT ID' ? hiddenColumnStyle : undefined}
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      {(data.length > pageSize || pageSize != 10) && (
        <Flex justifyContent="space-between" m={4} alignItems="center">
          <Flex>
            <Tooltip label="First Page">
              <IconButton
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label="Previous Page">
              <IconButton
                onClick={previousPage}
                isDisabled={!canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>

          <Flex alignItems="center">
            <Text flexShrink="0" mr={8}>
              Page{' '}
              <Text fontWeight="bold" as="span">
                {pageIndex + 1}
              </Text>{' '}
              of{' '}
              <Text fontWeight="bold" as="span">
                {pageOptions.length}
              </Text>
            </Text>
            <Text flexShrink="0">Go to page:</Text>{' '}
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={pageOptions.length}
              onChange={(value) => {
                const page = value ? value - 1 : 0;
                gotoPage(page);
              }}
              defaultValue={pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              w={32}
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </Select>
          </Flex>

          <Flex>
            <Tooltip label="Next Page">
              <IconButton
                onClick={nextPage}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
            <Tooltip label="Last Page">
              <IconButton
                onClick={() => gotoPage(pageCount - 1)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
              />
            </Tooltip>
          </Flex>
        </Flex>
      )}
      {roiByFund !== undefined &&
      totalInvestmentAmountByFund !== undefined &&
      totalInvestmentValueByFund !== undefined ? (
        <Box px="20px" fontSize="18px" mt="25px">
          <Box mb="4px" display="flex" justifyContent="space-between" flexDirection="row">
            <Text>Total Investment Value:</Text>
            <Text>{`$${formatNumber(totalInvestmentValueByFund)}`}</Text>
          </Box>
          <Box mb="4px" display="flex" justifyContent="space-between" flexDirection="row">
            <Text>Total Invested Amount:</Text>
            <Text>{`$${formatNumber(totalInvestmentAmountByFund)}`}</Text>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <Text>Return on Investment (ROI): </Text>
            <Text>{`${roiByFund.toFixed(2)}%`}</Text>
          </Box>
        </Box>
      ) : null}
    </Card>
  );
}
