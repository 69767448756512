import { Icon } from '@chakra-ui/react';
import { MdAccessAlarm, MdBarChart, MdHome, MdLock, MdOutlineSpaceDashboard } from 'react-icons/md';

import { HiUsers } from 'react-icons/hi2';

// Admin Imports
import ClientOverview from 'views/agent/clientOverview';
import Clients from 'views/agent/clients';
import Dashboard from 'views/agent/dashboard';
import InvestmentProjectionTool from 'views/agent/InvestmentProjectionTool';
import ProfileSettings from 'views/agent/profileSettings';

// Auth Imports
import AgentsOverview from 'views/admin/agentsOverview';
import WhitelistOverview from 'views/admin/whitelist';
import Alerts from 'views/agent/alerts';
import ImportClientProfile from 'views/agent/importClientProfile';
import ForgotPassword from 'views/auth/forgetPassword';
import SignInCentered from 'views/auth/signIn';
import SignUp from 'views/auth/signUp';

const routes = [
  {
    name: 'Main Dashboard',
    layout: '/agent',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: Dashboard
  },
  {
    name: 'Import Existing Client Profile',
    layout: '/agent',
    icon: <Icon as={MdOutlineSpaceDashboard} width="20px" height="20px" color="inherit" />,
    path: '/import-client-profile',
    component: ImportClientProfile
  },
  {
    name: 'Clients',
    layout: '/agent',
    icon: <Icon as={HiUsers} width="20px" height="20px" color="inherit" />,
    path: '/clients',
    component: Clients
  },
  {
    name: 'Investment Projection',
    layout: '/agent',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/investment-projection',
    component: InvestmentProjectionTool
  },
  {
    name: 'Alerts',
    layout: '/agent',
    icon: <Icon as={MdAccessAlarm} width="20px" height="20px" color="inherit" />,
    path: '/alerts',
    component: Alerts
  },
  {
    name: 'Client Individual Overview',
    layout: '/agent',
    path: '/client',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ClientOverview,
    hide: true
  },
  {
    name: 'Edit Profile',
    layout: '/agent',
    path: '/profile-settings',
    component: ProfileSettings,
    hide: true
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    component: SignInCentered
  },
  {
    name: 'Forgot Password',
    layout: '/auth',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'Sign Up',
    layout: '/auth',
    path: '/sign-up',
    component: SignUp
  },
  {
    name: 'Admin Sign In',
    layout: '/auth',
    path: '/sign-in-admin',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: SignInCentered
  },
  {
    name: 'Admin Sign Up',
    layout: '/auth',
    path: '/sign-up-admin',
    component: SignUp
  },
  {
    name: 'Agents Overview',
    layout: '/admin',
    path: '/default',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: AgentsOverview
  },
  {
    name: 'Whitelist',
    layout: '/admin',
    path: '/whitelist',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: WhitelistOverview
  },
  {
    name: 'Edit Profile',
    layout: '/admin',
    path: '/profile-settings',
    component: ProfileSettings,
    hide: true
  }
];

export default routes;
