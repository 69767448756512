import {
    Box,
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Text,
    useColorModeValue
} from '@chakra-ui/react';

import { css } from '@emotion/react';
// import calendarIcon from '/assets/img/icons/calendar.png';

import calendarIconWhite from 'assets/img/icons/calendar.png';
import calendarIconBlack from 'assets/img/icons/calendarBlack.png';
import { formatFundName } from 'service/api';

export default function AddFund(props) {
  const {
    textColor,
    inputBg,
    inputText,
    handleAmount,
    amount,
    handleStartDate,
    startDate,
    handleProductName,
    productName,
    funds,
    fundOptions,
    addFund,
    removeFund,
    selectFund,
    selectNumUnit,
    handlePremiumAmount,
    premiumAmount,
    handlePremiumType,
    premiumType,
    premiums,
    addPremium,
    removePremium,
    selectAllocation,
    selectPremiumFund,
    handlePolicyFeeType,
    policyFeeType,
    handleWelcomeBonus,
    welcomeBonus,
    setDisableButton,
    inputTextPlaceholder
  } = props;
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');
  const calendarIcon = useColorModeValue(`${calendarIconBlack}`, `${calendarIconWhite}`);

  const filterFundOptions = (currentId, arr) => {
    const ids = arr.map((t) => parseInt(t.id));
    const filtered = fundOptions.filter(
      (o) => parseInt(o) == currentId || !ids.includes(parseInt(o))
    );
    return filtered;
  };

  return (
    <Flex justify="space-between" flexDirection="column" px="20px">
      <Box direction="column" w="100%" borderRadius="8px" border={`1px solid ${borderColor}`}>
        <Flex p="20px" justify="space-between">
          <Box direction="column" w="350px" px="0px" borderRadius="8px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Input Current Product Name
            </Text>

            <Input
              type="text"
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder="PRODUCT NAME"
              onChange={handleProductName}
              value={productName}
              isInvalid={productName === ''}
              border={`1px solid ${borderColor}`}
            />
          </Box>
        </Flex>

        <Flex px="20px" justify="space-between" gap="20px">
          <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Paid Till Date
            </Text>

            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="100"
                onChange={handleAmount}
                value={amount}
                isInvalid={amount === 0}
                border={`1px solid`}
                borderColor={borderColor}
              />
            </InputGroup>
          </Box>

          <Box
            direction="column"
            w="100%"
            px="0px"
            borderRadius="8px"
            mb="20px"
            css={css`
              input::-webkit-datetime-edit-day-field:focus,
              input::-webkit-datetime-edit-month-field:focus,
              input::-webkit-datetime-edit-year-field:focus {
                color: ${inputText};
                outline: none;
              }
              input[type='date']:focus {
                color: ${inputTextPlaceholder};
                outline: none;
              }
            `}
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Start Date
            </Text>
            <Input
              variant="date"
              fontSize="sm"
              bg={inputBg}
              color={textColor}
              fontWeight="500"
              type="date"
              onChange={handleStartDate}
              value={startDate}
              _placeholder={{ color: { textColor }, fontSize: '14px' }}
              borderRadius="30px"
              css={css`
                ::-webkit-calendar-picker-indicator {
                  background: url(${calendarIcon}) center/80% no-repeat;
                  color: black;
                }
              `}
              isInvalid={startDate}
              border={startDate === '' ? '2px solid' : `1px solid`}
              borderColor={startDate === '' ? '#FC8181' : ` ${borderColor}`}
            />
          </Box>
        </Flex>

        <Flex px="20px" justify="space-between" gap="20px">
          <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Total Welcome Bonus
            </Text>

            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="100"
                onChange={handleWelcomeBonus}
                value={welcomeBonus}
                isInvalid={welcomeBonus === 0}
                border={welcomeBonus === 0 ? '2px solid' : `1px solid`}
                borderColor={welcomeBonus === 0 ? '#FC8181' : ` ${borderColor}`}
              />
            </InputGroup>
          </Box>

          <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Policy Fee Deduction Type
            </Text>
            <Select
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              onChange={(e) => {
                handlePolicyFeeType(e);
                setDisableButton(e.target.value);
              }}
              mb="4px"
              value={policyFeeType}
              isInvalid={!policyFeeType}
              border={policyFeeType !== 'null' ? '1px solid #FC8181' : '2px solid #FC8181'}
              borderColor={policyFeeType !== 'null' ? borderColor : '#FC8181'}
            >
              <option value="null">None</option>
              <option value="op0">OP - 0</option>
              <option value="np1">NP - 1</option>
              <option value="np2">NP - 2</option>
            </Select>
            <Text color={policyFeeType === 'null' ? '#FC8181' : textColor} fontSize="12px">
              {policyFeeType === 'op0'
                ? 'Old Plan: 2.5% and 0% from 11th year'
                : policyFeeType === 'np1'
                ? 'New Plan 1: 2.5% and 0.6% from 11th year'
                : policyFeeType === 'np2'
                ? 'New Plan 2: 1.5% and 0.6% from 16th year'
                : "Please select 'Policy Fee Deduction Type'"}
            </Text>
          </Box>
        </Flex>

        {funds.map((element, index) => (
          <Flex px="20px" justify="space-between" key={index} gap="20px">
            <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                {`Current Fund Balance ${index + 1}`}
              </Text>
              <Select
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                borderRadius="30px"
                fontSize="sm"
                value={element.id}
                onChange={(e) => selectFund(index, e.target.value)}
                isInvalid={!element.id}
                border={`1px solid`}
                borderColor={borderColor}
              >
                {filterFundOptions(element.id, funds).map((fundOption) => {
                  return (
                    <option value={fundOption} key={fundOption}>
                      {formatFundName(fundOption)}
                    </option>
                  );
                })}
              </Select>
            </Box>

            <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Number of Units
              </Text>

              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="e.g. 1"
                value={element.num}
                onChange={(e) => selectNumUnit(index, e.target.value)}
                isInvalid={element.num === 0}
                border={'1px solid'}
                borderColor={borderColor}
              />
            </Box>
          </Flex>
        ))}

        {funds.length < 32 && (
          <Flex my="25px" justify="center" width="100%" gap="20px">
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={addFund}
              disabled={funds.length >= 32}
            >
              +
            </Button>
            <Button
              variant={'outline'}
              fontSize="16px"
              onClick={removeFund}
              disabled={funds.length <= 1}
            >
              -
            </Button>
          </Flex>
        )}
      </Box>

      <Box
        direction="column"
        w="100%"
        mt="20px"
        pt="20px"
        borderRadius="8px"
        border={`1px solid ${borderColor}`}
      >
        <Flex px="20px" justify="space-between" gap="20px">
          <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Amount
            </Text>

            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="100"
                onChange={handlePremiumAmount}
                value={premiumAmount}
                isInvalid={premiumAmount === 0}
                border={`1px solid`}
                borderColor={borderColor}
              />
            </InputGroup>
          </Box>
          <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Frequency
            </Text>
            <Select
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              mt="5px"
              onChange={handlePremiumType}
              value={premiumType}
              border={`1px solid`}
              borderColor={borderColor}
            >
              <option value="monthly">MONTHLY</option>
              <option value="annually">ANNUALLY</option>
              <option value="lumpsum">LUMP-SUM</option>
            </Select>
          </Box>
        </Flex>

        {premiums.map((element, index) => (
          <Flex px="25px" justify="space-between" key={index} gap="20px">
            <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                {`Fund ${index + 1}`}
              </Text>
              <Select
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                borderRadius="30px"
                fontSize="sm"
                onChange={(e) => selectPremiumFund(index, e.target.value)}
                value={element.id}
                isInvalid={!element.id}
                border={`1px solid`}
                borderColor={borderColor}
              >
                {filterFundOptions(element.id, premiums).map((fundOption) => {
                  return (
                    <option value={fundOption} key={fundOption}>
                      {formatFundName(fundOption)}
                    </option>
                  );
                })}
              </Select>
            </Box>

            <Box direction="column" w="100%" px="0px" borderRadius="8px" mb="20px">
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Allocation (%)
              </Text>

              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="e.g. % of Allocation"
                value={element.num}
                border={`1px solid`}
                borderColor={borderColor}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '' || (Number(value) <= 100 && Number(value) >= 0)) {
                    selectAllocation(index, value);
                  }
                }}
              />
            </Box>
          </Flex>
        ))}

        {premiums.length < 32 && (
          <Flex my="25px" justify="center" width="100%" gap="20px">
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={addPremium}
              disabled={premiums.length >= 32}
            >
              +
            </Button>
            <Button
              variant={'outline'}
              fontSize="16px"
              onClick={removePremium}
              disabled={premiums.length <= 1}
            >
              -
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
