export const columnsAlert = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'PRICE',
    accessor: 'price'
  },
  {
    Header: 'DATE CREATED',
    accessor: 'date'
  },
  {
    Header: 'TRIGGERED DATE',
    accessor: 'triggered_date',
    style: { width: '150px' }
  }
];

export const columnsCurrentAlert = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'PRICE',
    accessor: 'price'
  },
  {
    Header: 'DATE CREATED',
    accessor: 'date'
  },
  {
    Header: 'REMOVE ALERT',
    accessor: 'delete'
  }
];
