// Chakra imports
import { Box, Grid, GridItem, SimpleGrid, useDisclosure, Icon, Button } from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';

import { columnsWhitelist } from 'views/admin/whitelist/variables/columnsData';
import tableWhitelist from 'views/admin/whitelist/variables/whitelist.json';

import React, { useEffect, useState } from 'react';
import UploadBox from './components/UploadBox';
import { getWhitelist } from 'service/api';
import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import { removeWhitelist } from 'service/api';
import ConfirmModal from 'components/confirmModal/ConfirmModal';

export default function WhitelistOverview() {
  const [agents, setAgents] = useState(tableWhitelist);
  const [deleteEmail, setDeleteEmail] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      const wl = await getWhitelist();
      setAgents(
        wl.map((e) => {
          return {
            email: e['email'],
            status: e['isRegistered'],
            delete: (
              <Button
                p="0"
                bg="transparent"
                onClick={() => {
                  onOpen();
                  setDeleteEmail(e['email']);
                }}
              >
                <Icon as={MdDelete} h="20px" w="20px" />
              </Button>
            )
          };
        })
      );
    };

    fetchData();
  }, []);

  const handleDeleteWhitelist = async () => {
    try {
      await removeWhitelist([deleteEmail]);
      window.location.reload(true);
    } catch (err) {
      alert(err);
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <BasicTableWithPagination
          columnsData={columnsWhitelist}
          tableData={agents}
          title="Live Agents"
        />
      </SimpleGrid>
      <Grid templateColumns="repeat(4, 1fr)" gap="20px" mb="20px">
        <GridItem colSpan={1}>
          <UploadBox />
        </GridItem>
      </Grid>
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleDeleteWhitelist}
        buttonText={'Continue editing'}
      />
    </Box>
  );
}
