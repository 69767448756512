import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';

export default function ConfirmModal(props) {
  const { isOpen, onClose, onSubmit, message, disabled, buttonText } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="white" maxW="617px" width="90%">
        <ModalHeader fontSize="40px" textAlign="center" color="black" fontWeight="400" pb="5px">
          Are You Sure?
        </ModalHeader>
        <ModalCloseButton color="black" />
        <ModalBody textAlign="center" color="grey" pt="0px">
          {message}
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="center" gap="21px">
            <Button colorScheme="blue" onClick={onSubmit} disabled={disabled}>
              Confirm
            </Button>
            <Button colorScheme="green" onClick={onClose}>
              {buttonText}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
