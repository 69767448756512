export const transactionOptions = [
  { value: '', label: '+' },
  { value: 'Welcome Bonus', label: 'Welcome Bonus' },
  { value: 'Premium Payment', label: 'Premium Payment' },
  { value: 'Policy Fees', label: 'Policy Fees' },
  { value: 'Switch In', label: 'Switch In' },
  { value: 'Switch Out', label: 'Switch Out' },
  { value: 'Premium Top-up', label: 'Premium Top-up' },
  { value: 'Reduction in Premium', label: 'Reduction in Premium' },
  { value: 'Premium Apportionment', label: 'Premium Apportionment' },
  { value: 'ILP Withdrawal', label: 'ILP Withdrawal' }
];
