export function generateColors(N) {
    const colorSet = [
      '#FFB6C1', // Light Pink
      '#FFD700', // Gold
      '#90EE90', // Light Green
      '#87CEEB', // Sky Blue
      '#FF00CB', // Light Coral
      '#E0FFFF', // Light Cyan
      '#FFDAB9', // Peachpuff
      '#ADD8E6', // Light Blue
      '#00FB98', // Pale Green
      '#F0E68C'  // Khaki
    ];
    
    const colors = [];

    for (let i = 0; i < N; i++) {
      colors.push(colorSet[i % colorSet.length]);
    }
    return colors;
  }