export const columnsAgents = [
  {
    Header: 'ID',
    accessor: 'id'
  },
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'EMAIL',
    accessor: 'email'
  },
  {
    Header: 'PHONE NUMBER',
    accessor: 'phone_number'
  },
  {
    Header: 'STATUS',
    accessor: 'status'
  },
  {
    Header: 'ACTION',
    accessor: 'action'
  }
];
