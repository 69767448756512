export const columnsClients = [
  {
    Header: 'CLIENT ID',
    accessor: 'id'
  },
  {
    Header: 'CLIENT NAME',
    accessor: 'name'
  },
  {
    Header: 'DATE IMPORTED',
    accessor: 'start_date'
  },
  {
    Header: 'TOTAL INVESTED',
    accessor: 'total_invested'
  },
  {
    Header: 'AUM',
    accessor: 'aum'
  },
  {
    Header: 'Overall XIRR',
    accessor: 'xirr'
  },
  {
    Header: 'Overall ROI',
    accessor: 'roi'
  },
  {
    Header: 'PRODUCT(S)',
    accessor: 'products'
  },
  {
    Header: 'DELETE',
    accessor: 'delete'
  }
];
