import { ChakraProvider } from '@chakra-ui/react';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import 'assets/css/App.css';
import AdminLayout from 'layouts/admin';
import AgentLayout from 'layouts/agent';
import AuthLayout from 'layouts/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { hasAdminTokens, hasTokens } from 'service/token';
import theme from 'theme/theme';

import { Toaster } from 'react-hot-toast'; // Import Toaster
import { history } from 'service/history';
import ResponsiveMessage from './mobileMessage'; // Import the new component

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasTokens() ? <Component {...props} /> : <Redirect to={{ pathname: '/auth/sign-in' }} />
      }
    />
  );
};

const PrivateAdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasAdminTokens() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth/sign-in' }} />
        )
      }
    />
  );
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <Toaster />
        <ResponsiveMessage>
          <BrowserRouter hist={history}>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <PrivateRoute path={`/agent`} component={AgentLayout} />
              <PrivateAdminRoute path={`/admin`} component={AdminLayout} />
              <Redirect from="/" to="/auth/sign-in" />
            </Switch>
          </BrowserRouter>
        </ResponsiveMessage> {/* Wrap the main content */}
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
);
