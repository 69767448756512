export const storeTokens = (jwt, identity, name) => {
  localStorage.setItem('identity', identity);
  localStorage.setItem('jwt', jwt);
  localStorage.setItem('name', name);
};

export const storeAdminTokens = (jwt, identity, name) => {
  localStorage.setItem('identity', identity);
  localStorage.setItem('jwt', jwt);
  localStorage.setItem('name', name);
  localStorage.setItem('isAdmin', true);
};

export const storeJWT = (jwt) => {
  localStorage.setItem('jwt', jwt);
};

export const hasTokens = () => {
  return localStorage.getItem('jwt') &&
    localStorage.getItem('identity') &&
    localStorage.getItem('name')
    ? true
    : false;
};

export const hasAdminTokens = () => {
  return hasTokens() && localStorage.getItem('isAdmin');
};

export const clearTokens = () => {
  localStorage.clear();
};

export const getJWT = () => {
  return localStorage.getItem('jwt');
};

export const getName = () => {
  return localStorage.getItem('name');
};

export const getIdentity = () => {
  return localStorage.getItem('identity');
};
