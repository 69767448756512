export const columnsDataProducts = [
  {
    Header: 'NO',
    accessor: 'no'
  },
  {
    Header: 'FUND',
    accessor: 'asset'
  },
  {
    Header: 'START DATE',
    accessor: 'start_date'
  },
  {
    Header: 'UNITS',
    accessor: 'units'
  },
  {
    Header: 'CURRENT VALUE',
    accessor: 'value'
  },
  {
    Header: 'CURRENT PRICE',
    accessor: 'current_price'
  },
  {
    Header: 'ROI',
    accessor: 'roi'
  },
  {
    Header: 'AVERAGE PRICE',
    accessor: 'average_price'
  }
];

export const columnsDataTransactions = [
  {
    Header: 'Transaction Type',
    accessor: 'type'
  },
  {
    Header: 'Application Type',
    accessor: 'applicationType'
  },
  {
    Header: 'Transaction Unit',
    accessor: 'units'
  },
  {
    Header: 'Transaction Amount',
    accessor: 'amount'
  },
  {
    Header: 'Run Date',
    accessor: 'runDate'
  },
  {
    Header: 'Price Effective Date',
    accessor: 'priceEffectiveDate'
  },
  {
    Header: 'Balance Units',
    accessor: 'balanceUnits'
  }
];

export const columnsTransactionsAudit = [
  {
    Header: 'Application Type',
    accessor: 'applicationType'
  },
  {
    Header: 'Date',
    accessor: 'date'
  },
  {
    Header: 'Transaction Type',
    accessor: 'type'
  },
  {
    Header: 'Undo',
    accessor: 'undo'
  }
];
