export function capitalizeFirstLetter(string) {
  if (string.length == 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatDateYYYMMDD = (dateString) => {
  const dateObj = new Date(dateString);

  // Get the date and time components from the Date object
  const year = dateObj.getFullYear();
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);

  // Format the date and time components into a string
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

// Example implementation of formatDateYYYMMDD
export function formatDateDDMMYYYY(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(d.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function convertDateToRender(dateString) {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
}

export function convertDateToString(dateString) {
  const [day, month, year] = dateString.split('/');

  return `${year}-${month}-${day}`;
}

export const cleanTextContent = (text) => {
  return text
    .replace(/[\n\t]/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};

export function convertToISO(dateString) {
  const [day, month, year] = dateString.split('/');

  const date = new Date(`${year}-${month}-${day}`);

  const now = new Date();

  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();
  const milliseconds = now.getMilliseconds();

  const isoString = new Date(date.setHours(hours, minutes, seconds, milliseconds))
    .toISOString()
    .replace('Z', `.${String(milliseconds).padEnd(9, '0')}Z`);

  return isoString;
}

export const cleanNumbersContent = (text) => {
  return text
    .replace(/[^\p{L}\s]/gu, '')
    .replace(/^\s+/, '')
    .trim();
};

export const formatDateStrict = (dateString) => {
  const dateObj = new Date(dateString);

  if (isNaN(dateObj.getTime())) {
    console.error('Invalid date string:', dateString);
    return 'Invalid Date';
  }

  const year = dateObj.getFullYear();
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
  const day = ('0' + dateObj.getDate()).slice(-2);

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

export const getStrictDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
