// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';
import { columnsAgents } from 'views/admin/agentsOverview/variables/columnsData';
import tableAgents from 'views/admin/agentsOverview/variables/tableAgents.json';

import React, { useEffect, useState } from 'react';
import AgentsTable from './components/AgentsTable';
import { getSuspendedAgents } from 'service/api';
import { getActiveAgents } from 'service/api';
import { getAgent } from 'service/api';
import { suspendAgent } from 'service/api';
import { unsuspendAgent } from 'service/api';
import toast from 'react-hot-toast';

export default function AgentsOverview() {
  const [liveAgents, setLiveAgents] = useState(tableAgents);
  const [suspendedAgents, setSuspendedAgents] = useState(tableAgents);
  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  const suspend = async (id) => {
    try {
      const agent = await getAgent(id);
      await suspendAgent(agent['email']);
      window.location.reload(true);
    } catch (err) {
      toastoastErrort(err);
    }
  };

  const resume = async (id) => {
    try {
      const agent = await getAgent(id);
      await unsuspendAgent(agent['email']);
      window.location.reload(true);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const baddies = await getSuspendedAgents();
      setSuspendedAgents(
        baddies.map((e) => {
          return {
            id: e['ID'],
            name: e['name'],
            email: e['email'],
            phone_number: e['phoneNumber'],
            status: false,
            action: 1001
          };
        })
      );
      const goodies = await getActiveAgents();
      setLiveAgents(
        goodies.map((e) => {
          return {
            id: e['ID'],
            name: e['name'],
            email: e['email'],
            phone_number: e['phoneNumber'],
            status: true,
            action: 1001
          };
        })
      );
    };

    fetchData();
  }, []);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb="20px" columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
        <AgentsTable
          columnsData={columnsAgents}
          tableData={liveAgents}
          live={true}
          action={suspend}
        />

        <AgentsTable
          columnsData={columnsAgents}
          tableData={suspendedAgents}
          live={false}
          action={resume}
        />
      </SimpleGrid>
    </Box>
  );
}
