import React from 'react';
import ReactApexChart from 'react-apexcharts';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: [],
      chartOptions: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.chartOptions !== this.props.chartOptions
    ) {
      this.updateChartData(this.props.chartData, this.props.chartOptions);
    }
  }

  updateChartData(chartData, chartOptions) {
    this.setState({
      chartData,
      chartOptions
    });
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions,
    });
  }

  render() {
    return (
      <div style={{ height: '350px' }}>
        <ReactApexChart
          options={{
            ...this.state.chartOptions,
            chart: {
              ...this.state.chartOptions.chart,
              zoom: {
                enabled: true,
                type: 'x',
                resetIcon: {
                  offsetX: -10,
                  offsetY: 0,
                  fillColor: '#fff',
                  strokeColor: '#37474F'
                },
                selection: {
                  background: '#90CAF9',
                  border: '#0D47A1'
                }
              },
              toolbar: {
                show: true,
                tools: {
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                  download: false
                }
              },
              panning: {
                enabled: true,
                mode: 'x'
              }
            }
          }}
          series={this.state.chartData}
          type="line"
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default LineChart;
