import {
  Box,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
import { useState } from 'react';
import { formatNumber } from 'utils/numbers';

function makeSafe(value) {
  // Checking for NaN:
  if (isNaN(value)) {
    return 0.0;
  }

  // Checking for positive infinity:
  if (value === Infinity) {
    return 999999.99;
  }

  // Checking for negative infinity:
  if (value === -Infinity) {
    return -999999.99;
  }

  // Checking for null value (zero in this case):
  if (value === null || value === undefined) {
    return 0.0;
  }

  return value;
}

function getXirrSafe(assetValue, totalInvested, years) {
  const value = Math.pow(assetValue / totalInvested, 1.0 / years) - 1;
  return makeSafe(value);
}

const Breakdown = (props) => {
  const bgBlock = useColorModeValue('#DFDFDF', '#162744');
  const textColor = useColorModeValue('#162744', '#DFDFDF');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');
  const [borderColorState, setBorderColor] = useState(useColorModeValue('#162744', '#DFDFDF'));

  const { iproducts, chartData } = props;
  const [finalData, setFinalData] = useState([]);

  const RowCalculation = async (e) => {
    const productId = e.target.value.split('-')[1];

    // Reset finalData when "Select Product" is chosen
    if (productId === undefined || productId === 'select product') {
      setFinalData([]); // Clear the table data
      return; // Exit the function
    }

    // Update border color based on the selected product
    setBorderColor(productId === '1' ? '#4318FF' : productId === '2' ? '#39B8FF' : 'white');

    const newFinalData = [];

    const start = iproducts[0].startDate;
    const startingYear = new Date(start).getFullYear();
    const labels = chartData['labels'];
    const datapoints = chartData['datasets'][productId - 1]['data'];

    let totalCapital =
      iproducts[productId - 1].frequency === 'lump-sum'
        ? parseFloat(iproducts[productId - 1].annualInvestment)
        : 0;

    for (let i = 0; i < labels.length; i += 12) {
      const year = startingYear + i / 12;
      const startingBalance =
        datapoints[i] !== undefined ? datapoints[i] : datapoints[datapoints.length - 1];

      const annualInvestment =
        iproducts[productId - 1].frequency !== 'lump-sum'
          ? parseFloat(iproducts[productId - 1].annualInvestment)
          : 0;

      let eoyBalance;
      if (i + 11 < datapoints.length) {
        eoyBalance = datapoints[i + 11];
      } else {
        eoyBalance = datapoints[datapoints.length - 1];
      }

      totalCapital += annualInvestment;

      newFinalData.push({
        year: year,
        startingBalance: startingBalance.toFixed(2),
        annualInvestment: annualInvestment.toFixed(2),
        endOfYear: eoyBalance !== undefined ? eoyBalance.toFixed(2) : '-',
        totalCapital: totalCapital.toFixed(2),
        profitLoss: (eoyBalance - totalCapital).toFixed(2),
        XIRR: (getXirrSafe(eoyBalance, totalCapital, newFinalData.length + 1) * 100).toFixed(2)
      });
    }

    setFinalData(newFinalData);
  };

  return (
    <Box
      bg={bgBlock}
      borderRadius={'20px'}
      height={'auto'}
      border={`1px solid ${borderColor}`}
      p="20px"
      overflowX="hidden"
    >
      <div className="select-box">
        <Select
          fontWeight={'100'}
          bg={bgBlock}
          color={textColor}
          borderRadius="30px"
          fontSize="14px"
          width={'160px'}
          required
          onChange={RowCalculation}
          border={'1px solid'}
          placeholder="Select product"
          borderColor={borderColorState}
        >
          {iproducts.map((iproduct) => (
            <option
              value={`product-${iproduct.id}`}
              key={iproduct.id}
            >{`Product-${iproduct.id}`}</option>
          ))}
        </Select>
      </div>

      <div>
        <Text fontSize="30px">Year by year breakdown</Text>
      </div>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Year</Th>
              <Th>Starting Balance</Th>
              <Th>Annual Investment</Th>
              <Th>End of Year</Th>
              <Th>Total Capital</Th>
              <Th>Profit/Loss</Th>
              <Th>XIRR</Th>
            </Tr>
          </Thead>
          <Tbody>
            {finalData.map((row, id) => (
             <Tr key={id}>
              <Th>{row['year']}</Th>
              <Th>${formatNumber(row['startingBalance'])}</Th>
              <Th>${formatNumber(row['annualInvestment'])}</Th>
              <Th>${row['endOfYear'] === 'NaN' ? '-' : formatNumber(row['endOfYear'])}</Th>
              <Th>${formatNumber(row['totalCapital'])}</Th>
              <Th>{row['profitLoss'] === 'NaN' ? '-' : formatNumber(row['profitLoss'])}</Th>
              <Th>{formatNumber(row['XIRR'])}%</Th>
            </Tr>
            ))}
          </Tbody> 
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Breakdown;
