import { Button, Flex, Grid, Icon, Input, Select, Text } from '@chakra-ui/react';
import { css } from '@emotion/react';
import Card from 'components/card/Card';
import { useEffect, useRef, useState } from 'react';
import { MdDelete } from 'react-icons/md';

import { transactionOptions } from './transactionOptions';

const TransactionTable = ({
  showTransactions,
  transactions,
  setTransactions,
  textColor,
  helperTextColor,
  bgButton,
  buttonTextColor,
  bgHover,
  inputBg,
  inputTextPlaceholder,
  inputText,
  setDisableConfirm,
  borderColor
}) => {
  const [editableCell, setEditableCell] = useState({ rowIndex: null, field: null });
  const [cellDimensions, setCellDimensions] = useState({});
  const [mouseEnterIndex, setMouseEnterIndex] = useState(null);
  const cellRefs = useRef({});

  const validFundCodes = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '11',
    '12',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '175',
    '212',
    '213',
    '214',
    '215',
    '216',
    '222',
    '224',
    '225',
    '226',
    '227',
    '00'
  ];

  const validateForm = () => {
    return transactions.every(
      (transaction) =>
        transaction.applicationType &&
        transaction.fundCode &&
        transaction.units &&
        transaction.amount &&
        transaction.runDate &&
        transaction.priceEffectiveDate &&
        transaction.balanceUnits
    );
  };

  useEffect(() => {
    const hasInvalidFundCode = transactions.some(
      (transaction) => !validFundCodes.includes(transaction.fundCode)
    );

    setDisableConfirm(!validateForm() || hasInvalidFundCode);
  }, [transactions, setDisableConfirm]);

  useEffect(() => {
    const updateCellDimensions = () => {
      const newCellDimensions = {};
      Object.keys(cellRefs.current).forEach((key) => {
        const cell = cellRefs.current[key];
        newCellDimensions[key] = {
          width: cell.offsetWidth,
          height: cell.offsetHeight
        };
      });
      setCellDimensions(newCellDimensions);
    };

    updateCellDimensions();
    window.addEventListener('resize', updateCellDimensions);

    return () => {
      window.removeEventListener('resize', updateCellDimensions);
    };
  }, [transactions]);

  const handleDoubleClick = (index, field) => {
    setEditableCell({ rowIndex: index, field });
  };

  const validateInput = (field, value, applicationType) => {
    if (value === '') {
      return value;
    }

    const isSwitchOut = applicationType === 'Switch Out';
    const validators = {
      fundCode: (val) => val.replace(/[^0-9]/g, ''),
      units: (val) => {
        if (isSwitchOut) {
          return val
            .replace(/(?!^-)[^\d.]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/^(\d+\.\d{0,2}).*/, '$1');
        }
        return val
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*)\./g, '$1')
          .replace(/^(\d+\.\d{0,2}).*/, '$1');
      },
      amount: (val) => {
        if (isSwitchOut) {
          return val
            .replace(/(?!^-)[^\d.]/g, '')
            .replace(/(\..*)\./g, '$1')
            .replace(/^(\d+\.\d{0,2}).*/, '$1');
        }
        return val
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*)\./g, '$1')
          .replace(/^(\d+\.\d{0,2}).*/, '$1');
      },
      balanceUnits: (val) =>
        val
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*)\./g, '$1')
          .replace(/^(\d+\.\d{0,2}).*/, '$1')
    };

    return validators[field] ? validators[field](value) : value;
  };

  const handleInputChange = (index, field, value) => {
    const updatedTransactions = [...transactions];
    const applicationType = updatedTransactions[index].applicationType;

    if (field === 'runDate' || field === 'priceEffectiveDate') {
      updatedTransactions[index][field] = value;
    } else {
      updatedTransactions[index][field] = validateInput(field, value, applicationType);
    }
    setTransactions(updatedTransactions);
  };

  const handleSelectChange = (index, value) => {
    const updatedTransactions = [...transactions];
    updatedTransactions[index].applicationType = value;
    setTransactions(updatedTransactions);
  };

  const handleBlur = () => {
    setEditableCell({ rowIndex: null, field: null });
  };

  const createTransactionManually = (index = transactions.length) => {
    const currentScrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    const startDate = new Date().toJSON();

    const newTransaction = {
      type: 'imported',
      applicationType: '',
      fundCode: '',
      amount: '',
      units: '',
      price: '',
      runDate: '',
      priceEffectiveDate: '',
      balanceUnits: '',
      startDate
    };

    const updatedTransactions = [...transactions];
    updatedTransactions.splice(index, 0, newTransaction);

    setTransactions(updatedTransactions);
    setEditableCell({ rowIndex: index, field: null });

    requestAnimationFrame(() => {
      window.scrollTo(0, currentScrollTop);
    });
  };

  const deleteTransaction = (indexToDelete) => {
    const updatedTransactions = transactions.filter((_, index) => index !== indexToDelete);
    setTransactions(updatedTransactions);
  };

  return (
    (showTransactions || transactions.length > 0) && (
      <Card border={`1px solid ${borderColor}`} mb={`20px`}>
        <Flex
          justify="space-between"
          flexDirection="column"
          css={css`
            input::-webkit-datetime-edit-day-field:focus,
            input::-webkit-datetime-edit-month-field:focus,
            input::-webkit-datetime-edit-year-field:focus {
              color: ${inputText};
              outline: none;
            }
            input[type='date']:focus {
              color: ${inputTextPlaceholder};
              outline: none;
            }
          `}
        >
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Review imported product transactions
          </Text>
          <Text
            color={helperTextColor}
            fontSize="16px"
            fontWeight="500"
            lineHeight="100%"
            padding="0.625rem 0"
          >
            DISCLAIMER: DO NOT PROVIDE ANY SENSITIVE INFORMATION
          </Text>
          <Text color={textColor} mt="5px" fontSize="24px" fontWeight="800" lineHeight="100%">
            Historical Transactions
          </Text>
          <Flex direction="column" gap="2px">
            <Grid
              templateColumns="40px 100px 175px 80px repeat(2, 1fr) 115px 115px 1fr 45px"
              gap="3px"
              mt="15px"
            >
              <Text textAlign="center" fontWeight="bold">
                Row
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Transaction Type
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Application Type
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Fund Code
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Transaction Unit
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Transaction Amount
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Run Date
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Price Effective Date
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Balance Units
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Delete
              </Text>
            </Grid>
            {transactions.reverse().map((transaction, index) => {
              return (
                <Grid
                  templateColumns="40px 100px 175px 80px repeat(2, 1fr) 115px 115px 1fr 45px"
                  gap="3px"
                  key={index}
                  onMouseEnter={() => setMouseEnterIndex(index)}
                  onMouseLeave={() => setMouseEnterIndex(null)}
                  borderTop={
                    mouseEnterIndex === index ? `2px solid ${bgButton}` : '2px solid transparent'
                  }
                  position="relative"
                  pt="2px"
                >
                  {mouseEnterIndex === index && (
                    <Button
                      position="absolute"
                      left="-5px"
                      top="-10px"
                      bg={bgButton}
                      borderRadius="50%"
                      color={inputBg}
                      fontSize="20px"
                      _hover={{ bg: bgHover }}
                      w="18px"
                      h="18px"
                      minW="18px"
                      minH="18px"
                      padding="0"
                      onClick={() => createTransactionManually(index)}
                    >
                      +
                    </Button>
                  )}
                  <Text
                    alignSelf="center"
                    textAlign="center"
                    color={inputText}
                    fontSize="14px"
                    fontWeight="500"
                  >
                    {index + 1}
                  </Text>
                  <Text
                    alignSelf="center"
                    textAlign="center"
                    color={inputText}
                    fontSize="14px"
                    fontWeight="500"
                  >
                    {transaction.type}
                  </Text>
                  <Select
                    value={
                      transaction.applicationType === 'Net Investment Premium'
                        ? 'Premium Payment'
                        : transaction.applicationType === 'Policy Fee'
                        ? 'Policy Fees'
                        : transaction.applicationType || ''
                    }
                    onChange={(e) => handleSelectChange(index, e.target.value)}
                    autoFocus
                    size="sm"
                    variant="unstyled"
                    bg={inputBg}
                    borderRadius="16px"
                    color={textColor}
                    paddingInlineStart="5px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    isInvalid={transaction.applicationType === ''}
                    border={transaction.applicationType !== '' ? `1px solid` : '1px solid #FC8181'}
                    borderColor={transaction.applicationType !== '' ? borderColor : '#FC8181'}
                    disabled={transaction.type === 'automatic'}
                  >
                    {transactionOptions.map((option, idx) => (
                      <option
                        key={idx}
                        value={option.value}
                        style={{
                          textAlign: 'center',
                          fontSize: '18px'
                        }}
                      >
                        {option.label}
                      </option>
                    ))}
                  </Select>
                  {[
                    'fundCode',
                    'units',
                    'amount',
                    'runDate',
                    'priceEffectiveDate',
                    'balanceUnits'
                  ].map((field) => (
                    <Text
                      key={field}
                      ref={(el) => {
                        if (el) {
                          cellRefs.current[`${index}-${field}`] = el;
                        }
                      }}
                      textAlign="center"
                      color={inputText}
                      fontSize="14px"
                      fontWeight="500"
                      alignSelf="center"
                      onDoubleClick={() => handleDoubleClick(index, field)}
                      bg={inputBg}
                      cursor="pointer"
                      h="21px"
                      borderRadius="16px"
                      title="Double click to edit"
                      maxW={field === 'runDate' || field === 'priceEffectiveDate' ? '115px' : ''}
                      border={
                        (field === 'fundCode' && !validFundCodes.includes(transaction.fundCode)) ||
                        !transaction[field]
                          ? '1px solid #FC8181'
                          : `1px solid ${borderColor}`
                      }
                      css={css`
                        input::-webkit-datetime-edit-day-field:focus,
                        input::-webkit-datetime-edit-month-field:focus,
                        input::-webkit-datetime-edit-year-field:focus {
                          color: ${inputText};
                          outline: none;
                        }
                        input[type='date']:focus {
                          color: ${inputTextPlaceholder};
                          outline: none;
                        }
                      `}
                    >
                      {editableCell.rowIndex === index &&
                      editableCell.field === field &&
                      transaction.type !== 'automatic' ? (
                        field === 'runDate' || field === 'priceEffectiveDate' ? (
                          <Input
                            type="date"
                            value={transaction[field] ? transaction[field] : ''}
                            onChange={(e) => handleInputChange(index, field, e.target.value)}
                            onBlur={handleBlur}
                            autoFocus
                            size="sm"
                            variant="unstyled"
                            bg="transparent"
                            borderRadius="16px"
                            color="white"
                            paddingInlineStart="5px"
                            maxW="120px"
                            maxWidth={cellDimensions[`${index}-${field}`]?.width || 'auto'}
                            maxHeight={cellDimensions[`${index}-${field}`]?.height || 'auto'}
                            height={cellDimensions[`${index}-${field}`]?.height || 'auto'}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          />
                        ) : (
                          <Input
                            value={transaction[field]}
                            onChange={(e) => handleInputChange(index, field, e.target.value)}
                            onBlur={handleBlur}
                            autoFocus
                            size="sm"
                            variant="unstyled"
                            bg="transparent"
                            borderRadius="16px"
                            color="white"
                            paddingInlineStart="5px"
                            maxWidth={cellDimensions[`${index}-${field}`]?.width || 'auto'}
                            maxHeight={cellDimensions[`${index}-${field}`]?.height || '21px'}
                            height={cellDimensions[`${index}-${field}`]?.height || '21px'}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          />
                        )
                      ) : field === 'runDate' || field === 'priceEffectiveDate' ? (
                        transaction[field].split('-').reverse().join('/')
                      ) : (
                        transaction[field] || ''
                      )}
                    </Text>
                  ))}

                  <Button bg="transparent" h="full" onClick={() => deleteTransaction(index)}>
                    <Icon as={MdDelete} h="17px" w="17px" />
                  </Button>
                </Grid>
              );
            })}
          </Flex>
          <Button
            mt="15px"
            color={buttonTextColor}
            fontSize="14px"
            bg={bgButton}
            _hover={bgHover}
            onClick={createTransactionManually}
            alignSelf="center"
            h="40px"
          >
            Add Transaction
          </Button>
        </Flex>
      </Card>
    )
  );
};
export default TransactionTable;
