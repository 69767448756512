import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';

import { useEffect, useRef, useState } from 'react';
import { MdAddCircle, MdBarChart, MdDelete, MdEdit } from 'react-icons/md';
import { useHistory, useLocation } from 'react-router-dom';

import PriceChanges from 'views/agent/clientOverview/components/PriceChanges';
import SummaryPieCard from 'views/agent/clientOverview/components/SummaryPieCard';

import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import ConfirmModal from 'components/confirmModal/ConfirmModal';
import IconBox from 'components/icons/IconBox';
import BasicTableWithPagination from 'components/table/BasicTableWithPagination';
import toast from 'react-hot-toast';
import { useReactToPrint } from 'react-to-print';
import {
  deleteProduct,
  formatFundName,
  getClientProducts,
  getClientTransactions,
  getFundCode,
  getFundCodeFromName,
  getFundsPricesByDay,
  getProductWithStats,
  getTransactions,
  removeClient
} from 'service/api';
import { generateColors } from 'utils/colors';
import { formatNumber, getRoiFund, limitDpTo } from 'utils/numbers';
import { convertDateToRender, formatDateDDMMYYYY } from 'utils/string';
import { columnsDataProducts } from 'views/agent/clientOverview/variables/columnsData';
import ImportClientForm from '../importClientProfile/components/ImportClientForm';
import AddProductModal from './components/AddProductModal';
import FundsOverview from './components/FundsOverview';
import ModifyClientDetailsModal from './components/ModifyClientDetailsModal';
import ProductOverview from './components/ProductOverview';
import StartTransactionModal from './components/StartTransactionModal';
import { TransactionAuditModal } from './components/TransactionAuditHistory';
import TransactionModal from './components/TransactionModal';
import { columnsDataTransactions } from './variables/columnsData';
import { defaultPiechartData, fillPiechartData } from './variables/piechartData';

export default function ClientOverview() {
  const location = useLocation();
  const history = useHistory();

  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const [piechartData, setPiechartData] = useState(defaultPiechartData);
  const [todaysPrices, setTodaysPrices] = useState([]);
  const [productsOverview, setProductsOverview] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({
    assetValueHistory: [0],
    breakdownPiechart: [],
    pnlHistory: [],
    product: {},
    roiHistory: [0],
    xirrHistory: [0]
  });
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [productFunds, setProductFunds] = useState([]);
  const [products, setProducts] = useState([]);
  const [transactionType, setTransactionType] = useState(1);
  const [lastProduct, setLastProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [deleteInx, setDeleteInx] = useState(0);
  const [showWelcomeBonus, setShowWelcomeBonus] = useState(true);
  const [total_investment, setTotalInvestment] = useState([]);
  const [manualTransactions, setManualTransactions] = useState([]);

  const componentRef = useRef(null);

  const print = useReactToPrint({
    pageStyle: `@media print {
      @page {
        size: 300mm 300mm;
        margin: 0;
      }
    }`,
    contentRef: componentRef
  });

  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  const {
    isOpen: isAddProductOpen,
    onOpen: onAddProductOpen,
    onClose: onAddProductClose
  } = useDisclosure();

  const {
    isOpen: isModifyClientOpen,
    onOpen: onModifyClientOpen,
    onClose: onModifyClientClose
  } = useDisclosure();

  const {
    isOpen: isStartTransactionOpen,
    onOpen: onStartTransactionOpen,
    onClose: onStartTransactionClose
  } = useDisclosure();

  const {
    isOpen: isTransactionOpen,
    onOpen: onTransactionOpen,
    onClose: onTransactionClose
  } = useDisclosure();

  const {
    isOpen: isEditProductOpen,
    onOpen: onEditProductOpen,
    onClose: onEditProductClose
  } = useDisclosure();

  const {
    isOpen: isTrasactionAuditOpen,
    onOpen: onTransactionAuditOpen,
    onClose: onTransactionAuditClose
  } = useDisclosure();

  const {
    isOpen: isDeleteProductOpen,
    onOpen: onDeleteProductOpen,
    onClose: onDeleteProductClose
  } = useDisclosure();

  const selectTransaction = (id) => {
    setTransactionType(id);
    onTransactionOpen();
  };

  const fetchTransactions = async () => {
    try {
      if (currentProduct.product['clientId'] && currentProduct.product['ID']) {
        const transactions = await getTransactions(
          currentProduct.product['clientId'],
          currentProduct.product['ID']
        );
        console.log(transactions, 'HERE');
        setTransactions(transactions);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setIsLoading(false);
    }
  };

  const fetchManualTransactions = async () => {
    try {
      const manualTransactions = await getClientTransactions(
        currentProduct.product['clientId'],
        currentProduct.product['ID'],
        100,
        0
      );

      const updatedManualTransactions = manualTransactions.map((transaction) => ({
        ...transaction,
        type: 'manual',
        applicationType: transaction.type,
        priceEffectiveDate: transaction.CreatedAt
      }));
      setManualTransactions(updatedManualTransactions);
    } catch (error) {
      console.error('Failed to fetch manual transactions:', error);
    }
  };

  const handleCurrentProductHelper = async (ps) => {
    setCurrentProduct(ps);
    if (transactions.length === 0) {
      return;
    }

    const funds = Object.keys(ps?.product.balances);
    const amount = Object.values(ps?.product.balances);
    const newProductFunds = [];

    let startDates = getFundsStartDate(transactions, funds);

    for (let i = 0; i < funds.length; i++) {
      if (amount[i] < 0.01) {
        continue;
      }

      let totalInvestmentAmount = 0;
      let totalInvestmentValue = 0;
      let roiFund = 0;

      const transactionsForFund = transactionsFund(getFundCode(funds[i]), [
        ...manualTransactions,
        ...transactions
      ]);

      if (transactionsForFund.length === 0) {
        roiFund = 0;
      } else {
        const totalInvestmentAmountForRoi =
          getTotalInvestmentAmountByFundForRoi(transactionsForFund);
        totalInvestmentAmount = getTotalInvestmentAmountByFund(transactionsForFund);
        totalInvestmentValue = ps.breakdownPiechart[funds[i]].toFixed(2);
        roiFund = getRoiFund(totalInvestmentAmountForRoi, totalInvestmentValue);
      }

      const fundCode = parseInt(getFundCode(funds[i]), 10);
      const date = startDates[fundCode];

      const averagePrice = getTotalInvestedAmountAlwaysWithBonus(transactionsForFund);

      const result = [...manualTransactions, ...transactions].reduce((acc, transaction) => {
        if (transaction.type === 'manual') {
          if (Number(transaction.fundId) === fundCode) {
            if (
              transaction.applicationType === 'Net Investment Premium' ||
              transaction.applicationType === 'Switch In' ||
              transaction.applicationType === 'Premium Top-up' ||
              transaction.applicationType === 'Welcome Bonus'
            ) {
              acc += transaction.transactionInfo.unit;
            } else if (
              transaction.applicationType === 'Switch Out' ||
              transaction.applicationType === 'ILP Withdrawal' ||
              transaction.applicationType === 'Policy Fee'
            ) {
              acc -= transaction.transactionInfo.unit;
            }
          }
        } else {
          if (transaction.fundCode === fundCode) {
            if (
              transaction.applicationType === 'Net Investment Premium' ||
              transaction.applicationType === 'Switch In' ||
              transaction.applicationType === 'Premium Top-up' ||
              transaction.applicationType === 'Welcome Bonus'
            ) {
              acc += transaction.units;
            } else if (
              transaction.applicationType === 'Switch Out' ||
              transaction.applicationType === 'ILP Withdrawal' ||
              transaction.applicationType === 'Policy Fee'
            ) {
              acc -= transaction.units;
            }
          }
        }
        return acc;
      }, 0);

      newProductFunds.push({
        no: i + 1,
        asset: formatFundName(funds[i]),
        start_date: date === '--/--/----' ? date : convertDateToRender(date),
        units: formatNumber(amount[i]),
        value: `$${formatNumber(ps.breakdownPiechart[funds[i]])}`,
        current_price: `$${formatNumber(ps.breakdownPiechart[funds[i]] / amount[i])}`,
        id: funds[i],
        roi: `${formatNumber(roiFund)}%`,
        average_price: `$${formatNumber(averagePrice / result)}`
      });
    }
    setProductFunds(newProductFunds);

    const colors = generateColors(Object.keys(ps.breakdownPiechart).length);
    const filtered = Object.keys(ps.breakdownPiechart).reduce((filtered, key) => {
      filtered[key] = ps.breakdownPiechart[key];
      return filtered;
    }, {});

    const byNumberDesc = (a, b) => parseFloat(filtered[b]) - parseFloat(filtered[a]);
    const labels = Object.keys(filtered)
      .sort(byNumberDesc)
      .map((e) => formatFundName(e));

    const options = fillPiechartData(labels, colors);
    const newPiechart = {
      ...piechartData,
      data: Object.values(filtered).sort((a, b) => b - a),
      options: options
    };
    setPiechartData(newPiechart);
    setIsLoading(false);
  };

  const handleCurrentProduct = async (p, index) => {
    try {
      const ps = await getProductWithStats(p);
      setCurrentProduct(ps);
      setCurrentProductIndex(index);
      localStorage.setItem('currentProductId', p.toString());

      fetchManualTransactions()
        .then((res) => {
          return handleCurrentProductHelper(ps);
        })
        .catch((error) => {
          console.error('Error during transaction handling:', error);
        });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      toastError(err);
    }
  };

  const onRefresh = () => {
    window.location.reload(true);
  };

  const handleDeleteProduct = async (id) => {
    if (products.length === 1) {
      setLastProduct(products[0].clientId);
      setDeleteInx(id);
      onDeleteProductOpen();
    } else {
      try {
        const success = await deleteProduct(id);

        if (success) {
          if (lastProduct) {
            const success2 = await removeClient(lastProduct);

            if (success2) {
              toastSuccess('Product deleted successfully.');
              toastSuccess('Client deleted successfully.');
              history.push('/agent/clients');
            } else {
              toastError('Failed to delete client.');
            }
          } else {
            toastSuccess('Product deleted successfully.');
            window.location.reload(true);
          }
        } else {
          toastError('Failed to delete product.');
        }
      } catch (error) {
        toastError('An error occurred while deleting the product.');
      }
    }
  };

  const cleanData = (data) => {
    data.assetValueHistory = data.assetValueHistory.map((f) => limitDpTo(f, 2));
    data.pnlHistory = data.pnlHistory.map((f) => limitDpTo(f, 2));
    data.roiHistory = data.roiHistory.map((f) => limitDpTo(f, 2));
    data.xirrHistory = data.xirrHistory.map((f) => limitDpTo(f, 2));
    return data;
  };

  function transactionsFund(fund, transactions) {
    if (fund.length < 4) {
      return transactions.filter((transaction) => {
        if (transaction.type === 'manual') {
          return +transaction.fundId === Number(fund);
        } else {
          return transaction.fundCode === Number(fund);
        }
      });
    } else {
      return transactions.filter((transaction) => {
        return (
          transaction.fundCode ===
          parseFloat(getFundCodeFromName(fund.asset.split('-').slice(1).join('-').trim()))
        );
      });
    }
  }

  function manualTransactionsFund(fundIDS) {
    return manualTransactions.filter((transaction) => {
      return transaction.fundId.includes(fundIDS);
    });
  }

  function getFundsStartDate(transactions, funds) {
    const fundCodes = funds.map((f) => +getFundCode(f));

    const fundStartDates = [...transactions, ...manualTransactions].reduce((acc, transaction) => {
      const { fundCode, runDate, CreatedAt, fundId } = transaction;
      const dateToUse = runDate ? new Date(runDate) : new Date(CreatedAt);

      let fundCodeToUse = [];

      if (fundCode) {
        fundCodeToUse = Array.isArray(fundCode) ? fundCode : [fundCode];
      } else if (fundId) {
        fundCodeToUse = fundId.split(',').map((f) => parseFloat(f));
      }

      fundCodeToUse.forEach((code) => {
        if (!acc[code] || dateToUse < new Date(acc[code])) {
          acc[code] = runDate || CreatedAt;
        }
      });

      return acc;
    }, {});

    const result = Object.keys(fundStartDates).reduce((acc, key) => {
      acc[key] = fundStartDates[key];
      return acc;
    }, {});

    fundCodes.forEach((code) => {
      if (!(code in result)) {
        result[code] = '--/--/----';
      }
    });

    return result;
  }

  function formatData(transactionsFund) {
    return transactionsFund.map((transaction) => {
      return {
        ...transaction,
        runDate: convertDateToRender(transaction.runDate),
        priceEffectiveDate: convertDateToRender(transaction.priceEffectiveDate),
        amount: transaction.amount.toFixed(2)
      };
    });
  }

  function formatDataForManually(transactionsFund) {
    return transactionsFund.map((transaction) => {
      const findedFund = transaction.transactionInfo;

      return {
        ...transaction,
        runDate: convertDateToRender(transaction.CreatedAt),
        priceEffectiveDate: convertDateToRender(transaction.priceEffectiveDate),
        amount: findedFund && findedFund.value ? findedFund.value : 0,
        units: findedFund && findedFund.unit ? findedFund.unit : 0,
        balanceUnits: findedFund && findedFund.balanceUnit ? findedFund.balanceUnit : 0
      };
    });
  }

  function getTotalInvestedAmountAlwaysWithBonus(transactions) {
    let totalInvestmentAmount =
      transactions.reduce((acc, d) => {
        if (
          d.applicationType === 'Net Investment Premium' ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Premium Top-up' ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Deposit'
        ) {
          return acc + Number(d.amount);
        } else if (d.applicationType === 'Switch Out' || d.applicationType === 'ILP Withdrawal') {
          return acc - Math.abs(Number(d.amount));
        } else if (d.applicationType === 'Welcome Bonus') {
          return acc + Number(d.amount);
        }
        return acc;
      }, 0) || 1;

    return totalInvestmentAmount;
  }

  function getTotalInvestmentAmountByFund(transactions) {
    const index = findCurrentCheckBox();

    let totalInvestmentAmount =
      transactions.reduce((acc, d) => {
        if (
          d.applicationType === 'Net Investment Premium' ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Premium Top-up' ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Deposit'
        ) {
          return acc + Number(d.amount);
        } else if (d.applicationType === 'Switch Out' || d.applicationType === 'ILP Withdrawal') {
          return acc - Math.abs(Number(d.amount));
        } else if (d.applicationType === 'Welcome Bonus' && showWelcomeBonus[index]) {
          return acc + Number(d.amount);
        }
        return acc;
      }, 0) || 1;

    return totalInvestmentAmount;
  }
  function getTotalInvestmentAmountByFundForRoi(transactions) {
    let totalInvestmentAmount =
      transactions.reduce((acc, d) => {
        if (
          d.applicationType === 'Net Investment Premium' ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Premium Top-up' ||
          d.applicationType === 'Switch In' ||
          d.applicationType === 'Deposit' ||
          d.applicationType === 'Premium Payment'
        ) {
          return acc + Number(d.amount);
        } else if (
          d.applicationType === 'Switch Out' ||
          d.applicationType === 'ILP Withdrawal' ||
          d.applicationType === 'Police Fees' ||
          d.applicationType === 'Policy Fee'
        ) {
          return acc - Math.abs(Number(d.amount));
        } else if (d.applicationType === 'Welcome Bonus') {
          return acc + Number(d.amount);
        }
        return acc;
      }, 0) || 1;

    return totalInvestmentAmount;
  }

  function getTotalInvestmentValueByFund(transactions) {
    let totalInvestmentValue = transactions.reduce((acc, d) => {
      let price = 0;
      if (d.type !== 'manual') {
        price = todaysPrices.find((el) => {
          return Number(getFundCode(el['fund_id'])) === d.fundCode;
        });
      } else {
        const id = d.fundId;
        const prices = todaysPrices.find((el) => {
          return Number(getFundCode(el['fund_id'])) === Number(getFundCode(id.trim()));
        });
        price = prices;
      }

      if (
        d.applicationType === 'Switch Out' ||
        d.applicationType === 'ILP Withdrawal' ||
        d.applicationType === 'Policy Fee' ||
        d.applicationType === 'Police Fees'
      ) {
        return acc - Number(price['bid_price']) * Number(d.units);
      } else if (
        d.applicationType === 'Net Investment Premium' ||
        d.applicationType === 'Premium Top-up' ||
        d.applicationType === 'Switch In' ||
        d.applicationType === 'Deposit' ||
        d.applicationType === 'Welcome Bonus' ||
        d.applicationType === 'Premium Payment'
      ) {
        return acc + Number(price['bid_price']) * Number(d.units);
      } else return acc;
    }, 0);

    return totalInvestmentValue;
  }

  function findCurrentCheckBox() {
    const findId = currentProduct.product.ID;
    const index = products.findIndex((el) => {
      return el['ID'] === findId;
    });
    return index;
  }

  useEffect(() => {
    const fetchManualTransactions = async () => {
      try {
        const manualTransactions = await getClientTransactions(
          currentProduct.product['clientId'],
          currentProduct.product.ID,
          100,
          0
        );

        const updatedManualTransactions = manualTransactions.map((transaction) => ({
          ...transaction,
          type: 'manual',
          applicationType: transaction.type,
          priceEffectiveDate: transaction.CreatedAt
        }));
        setManualTransactions(updatedManualTransactions);
      } catch (error) {
        console.error('Failed to fetch manual transactions:', error);
      }
    };

    if (currentProduct.product['clientId']) {
      fetchManualTransactions();
    }
  }, [currentProduct.product['clientId']]);

  useEffect(() => {
    const fetchPrices = async () => {
      const prices = await getFundsPricesByDay(formatDateDDMMYYYY(new Date()));
      setTodaysPrices(prices);
    };

    fetchPrices();
  }, []);

  useEffect(() => {
    const updateProductsOverview = () => {
      const updatedOverview = products.map((product, index) => {
        const currentProductOverview = productsOverview[index] || {};

        const totalInvested = product.totalInvested;
        const totalInvestedWithBonus = parseFloat(totalInvested + product.bonus);
        const updatedTotalInvestment = showWelcomeBonus[index]
          ? totalInvestedWithBonus
          : totalInvested;

        const aum = currentProductOverview.aum.split('$')[1].split(',');
        const aumNumber = aum[0] + aum[1];

        const updatedUnrealisedPL = +aumNumber - updatedTotalInvestment;

        const updatedXirr = showWelcomeBonus[index]
          ? currentProductOverview.xirrWithoutBonus
          : currentProductOverview.myXirr;

        const updatedRoi = showWelcomeBonus[index]
          ? currentProductOverview.roiWithoutBonus
          : currentProductOverview.myRoi;
        return {
          ...currentProductOverview,
          total_investment: updatedTotalInvestment,
          welcome_bonus: showWelcomeBonus[index],
          unrealised_pl: `$${formatNumber(updatedUnrealisedPL)}`,
          xirr: `${formatNumber(updatedXirr)}%`,
          roi: `${formatNumber(updatedRoi)}%`
        };
      });

      setProductsOverview(updatedOverview);
    };

    if (products.length > 0 && productsOverview.length > 0) {
      updateProductsOverview();
    }
  }, [showWelcomeBonus, products]);

  useEffect(() => {
    const storedProductId = localStorage.getItem('currentProductId');

    if (products.length > 0) {
      const productIndex = products.findIndex(
        (product) => product.ID.toString() === storedProductId
      );

      if (productIndex !== -1) {
        handleCurrentProduct(products[productIndex].ID, productIndex);
      } else {
        handleCurrentProduct(products[0].ID, 0);
      }
    } else {
      console.warn('No products available.');
    }
  }, [products]);

  const applyProducts = (arr) => {
    const res = [];
    for (let i = 0; i < arr.length; i++) {
      const product = arr[i].product;
      const assetValueHistory = arr[i].assetValueHistory || [];
      const pnlHistory = arr[i].pnlHistory || [];
      const xirrHistory = arr[i].xirrHistory || [];
      const roiHistory = arr[i].roiHistory || [];
      const unrealisedPL = assetValueHistory[0] - arr[i].totalInvestedWithoutBonus;
      const formattedUrealisedPL = formatNumber(unrealisedPL);

      res.push({
        productName: product.name,
        total_investment: product.totalInvested ? product.totalInvested : '0.00',
        aum: assetValueHistory[0] ? `$${formatNumber(assetValueHistory[0])}` : '0.00',
        unrealised_pl: pnlHistory[0] ? `$${formattedUrealisedPL}` : '0.00',
        xirr: arr[i].xirrHistoryWithoutBonuses[0]
          ? formatNumber(arr[i].xirrHistoryWithoutBonuses[0]) + '%'
          : '0.00',
        roi: arr[i].roiHistoryWithoutBonuses
          ? formatNumber(arr[i].roiHistoryWithoutBonuses[0]) + '%'
          : '0.00',
        welcome_bonus: showWelcomeBonus[i] || false,
        totalInvestedWithoutBonus: arr[i].totalInvestedWithoutBonus
          ? arr[i].totalInvestedWithoutBonus
          : '0.00',
        xirrWithoutBonus: xirrHistory[0] ? xirrHistory[0].toFixed(2) : '0.00',
        roiWithoutBonus: roiHistory[0] ? formatNumber(roiHistory[0]) + '%' : '0.00',
        myXirr: arr[i].xirrHistoryWithoutBonuses[0].toFixed(2),
        myRoi: formatNumber(arr[i].roiHistoryWithoutBonuses[0]) + '%'
      });

      setTotalInvestment((prev) => [
        ...prev,
        {
          ID: arr[i].product['ID'],
          assetValue: arr[i].assetValueHistory[0]
        }
      ]);
    }

    return res;
  };

  useEffect(() => {
    const fetchProducts = async () => {
      let i = location.pathname.replace('/agent/client/', '');
      try {
        setIsLoading(true);
        let x = parseInt(i);

        const p = await getClientProducts(x, 100, 0);

        setProducts(p);
        const pss = await Promise.all(
          p.map(async (e) => {
            const s = await getProductWithStats(e.ID);
            return s;
          })
        );

        setProductsOverview(applyProducts(pss));
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching products:', err);
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [location.pathname]);

  useEffect(() => {
    if (currentProduct?.product?.clientId) {
      fetchManualTransactions();
    }
  }, [currentProduct]);

  useEffect(() => {
    if (manualTransactions.length) {
      handleCurrentProductHelper(currentProduct);
    }
  }, [manualTransactions]);

  useEffect(() => {
    if (currentProduct?.product['clientId'] && currentProduct?.product['ID']) {
      fetchTransactions();
    }
  }, [currentProduct?.product]);

  useEffect(() => {
    if (transactions.length > 0 && currentProduct?.product) {
      handleCurrentProductHelper(currentProduct);
      fetchManualTransactions();
    }
  }, [transactions, currentProduct]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} ref={componentRef}>
      <Flex width="100%" margin="20px 0" gap="20px">
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          _hover={bgHover}
          onClick={onModifyClientOpen}
          shadow={'none'}
        >
          Modify Client Details
        </Button>
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          _hover={bgHover}
          onClick={() => print()}
          shadow={'none'}
        >
          Print Info
        </Button>
        <ModifyClientDetailsModal isOpen={isModifyClientOpen} onClose={onModifyClientClose} />
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" thickness="4px" speed="0.65s" emptyColor="gray.200" color={bgButton} />
        </Flex>
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '3xl': 1 }} gap="20px" mb="20px">
            {products.map((value, index) => {
              return (
                <Card
                  key={value.ID}
                  border={`2px solid ${index === currentProductIndex ? bgButton : borderColor}`}
                >
                  <Flex direction="row" justify="space-between" align="center">
                    <MiniStatistics
                      onClick={() => {
                        setIsLoading(true);
                        handleCurrentProduct(value.ID, index);
                      }}
                      p="0px"
                      startContent={
                        <IconBox
                          w="56px"
                          h="56px"
                          bg={bgButton}
                          _hover={bgHover}
                          title="Product Statistics"
                          icon={
                            <Icon
                              w="32px"
                              h="32px"
                              as={MdBarChart}
                              color={buttonTextColor}
                              cursor="pointer"
                            />
                          }
                        />
                      }
                      name="Product ID"
                      value={value.name}
                    />

                    <Flex direction="row" gap="10px">
                      <Button
                        bgColor="transparent"
                        _hover="transparent"
                        _active="transparent"
                        px="0"
                        onClick={onEditProductOpen}
                        title="Edit Product and Client info"
                        disabled={Object.keys(currentProduct?.product).length === 0}
                        shadow={'none'}
                      >
                        <IconBox
                          w="39px"
                          h="39px"
                          bg={bgButton}
                          _hover={bgHover}
                          icon={<Icon w="26px" h="26px" as={MdEdit} color={buttonTextColor} />}
                        />
                      </Button>
                      <Button
                        bgColor="transparent"
                        _hover="transparent"
                        _active="transparent"
                        px="0"
                        onClick={() => handleDeleteProduct(value.ID)}
                        title="Delete Product"
                        shadow={'none'}
                      >
                        <IconBox
                          w="39px"
                          h="39px"
                          bg={bgButton}
                          _hover={bgHover}
                          icon={<Icon w="26px" h="26px" as={MdDelete} color={buttonTextColor} />}
                        />
                      </Button>
                    </Flex>
                  </Flex>
                </Card>
              );
            })}
            <Box py="24px">
              <Button
                bgColor="transparent"
                _hover="transparent"
                _active="transparent"
                onClick={onAddProductOpen}
                px="0"
              >
                <IconBox
                  w="56px"
                  h="56px"
                  bg={bgButton}
                  _hover={bgHover}
                  icon={<Icon w="32px" h="32px" as={MdAddCircle} color={buttonTextColor} />}
                />
              </Button>
              <AddProductModal
                isOpen={isAddProductOpen}
                onClose={onAddProductClose}
                infoToAddNewProduct={currentProduct?.product}
              />
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
            <ProductOverview
              productsOverview={productsOverview}
              setShowWelcomeBonus={setShowWelcomeBonus}
              showWelcomeBonus={showWelcomeBonus}
              products={products}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap="20px" mb="20px">
            <SummaryPieCard
              piechartData={piechartData.data}
              piechartOptions={piechartData.options}
              vertical={false}
              hasDetails={true}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap="20px" mb="20px">
            <PriceChanges
              currentProduct={cleanData(currentProduct)}
              transactions={transactions}
              infoToAddNewProduct={currentProduct?.product}
            />
          </SimpleGrid>
          {/* <SimpleGrid columns={1} gap="20px" mb="20px">
            <TransactionBarChart transactions={transactions} />
          </SimpleGrid>
           */}
          <SimpleGrid columns={1} gap="20px" mb="20px">
            <FundsOverview
              fundsData={productFunds}
              transactions={[...transactions, ...manualTransactions]}
              showWelcomeBonus={showWelcomeBonus[findCurrentCheckBox()]}
              todaysPrices={todaysPrices}
            />
          </SimpleGrid>
          <SimpleGrid columns={1} gap="20px" mb="20px">
            {productFunds.map((fund, index) => {
              const formattedData = formatData(transactionsFund(fund, transactions));
              const formattedManulTransactions = formatDataForManually(
                manualTransactionsFund(fund.id)
              );

              if (formattedData.length > 0 || formattedManulTransactions.length > 0) {
                const totalInvestmentValue = getTotalInvestmentValueByFund([
                  ...formattedData,
                  ...formattedManulTransactions
                ]);

                const totalInvestmentAmount = getTotalInvestmentAmountByFund([
                  ...formattedData,
                  ...formattedManulTransactions
                ]);
                const roi =
                  totalInvestmentAmount > 0
                    ? getRoiFund(totalInvestmentAmount, totalInvestmentValue)
                    : 0;

                const doubleFormattedData = [...formattedData, ...formattedManulTransactions].map(
                  (el) => ({
                    ...el,
                    units: formatNumber(el.units),
                    amount: '$' + formatNumber(el.amount),
                    balanceUnits: formatNumber(el.balanceUnits)
                  })
                );

                return (
                  <BasicTableWithPagination
                    key={index}
                    columnsData={columnsDataTransactions}
                    tableData={doubleFormattedData}
                    title={fund.asset}
                    totalInvestmentValueByFund={totalInvestmentValue}
                    totalInvestmentAmountByFund={totalInvestmentAmount}
                    roiByFund={roi}
                    index={index}
                    showWelcomeBonus={showWelcomeBonus}
                  />
                );
              }

              return null;
            })}
          </SimpleGrid>
          <Flex px="25px" justify="center" width="100%" margin="20px 0" gap="10px">
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={onStartTransactionOpen}
            >
              Create Transaction
            </Button>
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={onTransactionAuditOpen}
            >
              View Audit History
            </Button>

            <StartTransactionModal
              isOpen={isStartTransactionOpen}
              onClose={onStartTransactionClose}
              selectTransaction={selectTransaction}
            />

            <TransactionAuditModal
              productId={currentProduct?.product['ID']}
              isOpen={isTrasactionAuditOpen}
              onClose={onTransactionAuditClose}
              transactions={transactions}
              isLoading={isLoading}
              manualTransactions={manualTransactions}
            />
          </Flex>
          <TransactionModal
            isOpen={isTransactionOpen}
            onClose={onTransactionClose}
            transactionType={transactionType}
            columnsData={columnsDataProducts}
            tableData={productFunds}
            piechartData={piechartData.data}
            piechartOptions={piechartData.options}
            product={currentProduct?.product}
            onRefresh={onRefresh}
            fundsData={productFunds}
            transactions={[...transactions, ...manualTransactions]}
            showWelcomeBonus={showWelcomeBonus[findCurrentCheckBox()]}
            todaysPrices={todaysPrices}
          />
          <EditProductModal
            isOpen={isEditProductOpen}
            onClose={onEditProductClose}
            borderColor={borderColor}
            productInfo={currentProduct?.product}
          />
          <ConfirmModal
            isOpen={isDeleteProductOpen}
            onClose={onDeleteProductClose}
            message={
              'This is the last product. Deleting it will remove the client as well. Are you sure?'
            }
            buttonText={'Cancel'}
            onSubmit={async () => {
              try {
                const success = await deleteProduct(deleteInx);

                if (success) {
                  if (lastProduct) {
                    const success2 = await removeClient(lastProduct);

                    if (success2) {
                      toastSuccess('Product deleted successfully.');
                      toastSuccess('Client deleted successfully.');
                      history.push('/agent/clients');
                    } else {
                      toastError('Failed to delete client.');
                    }
                  } else {
                    toastSuccess('Product deleted successfully.');
                    window.location.reload(true);
                  }
                } else {
                  toastError('Failed to delete product.');
                }
              } catch (error) {
                toastError('An error occurred while deleting the product.');
              }
              onDeleteProductClose();
            }}
          />
        </>
      )}
    </Box>
  );
}
const EditProductModal = ({ isOpen, onClose, productInfo, borderColor }) => {
  const bgColor = useColorModeValue('#DFDFDF', '#162744');

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w="95%" maxWidth="95%" border={`1px solid ${borderColor}`} bg={bgColor}>
        <ModalHeader fontSize="24px" fontWeight="400">
          Edit Product and Client info
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ImportClientForm productInfo={productInfo} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
