import ConfigForm from './components/ConfigForm';

const InvestmentProjectionTool = () => {
  return (
    <div>
      <ConfigForm></ConfigForm>
    </div>
  );
};

export default InvestmentProjectionTool;
