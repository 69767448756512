/* eslint-disable */
import { Button, Flex, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { createExistingProduct } from 'service/api';

import UploadModal from 'components/uploadModal/UploadModal';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import {
    createTransactions,
    getFundIdsWithAppendedNullOption,
    funds as productFundsBase
} from 'service/api';
import { parseNumberOrZero } from 'utils/numbers';
import { cleanNumbersContent, cleanTextContent, convertDateToString } from 'utils/string';
import { ProductInfo } from 'views/agent/createClientProfile/components/AddProduct';
import TransactionTable from 'views/agent/importClientProfile/components/TransactionTable';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';
import AddFund from '../../importClientProfile/components/AddFund';

export default function ImportClientProductOnly(props) {
  const textColor = useColorModeValue('white.100', 'white.100');
  const buttonTextColor = useColorModeValue('black', 'black');
  const helperTextColor = useColorModeValue('secondaryGray.900', 'whiteAlpha.600');
  const cardColor = useColorModeValue('white', 'whiteAlpha.100');
  const inputBg = useColorModeValue('#DFDFDF', '#162744');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const [amount, setAmount] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [productName, setProductName] = useState('');
  let i = location.pathname.replace('/agent/client/', '');
  let x = parseInt(i);
  const accountId = x;
  const [funds, setFunds] = useState([]);
  const [fundOptions, setFundOptions] = useState([]);

  const [premiumAmount, setPremiumAmount] = useState(0);
  const [premiumType, setPremiumType] = useState('monthly');
  const [premiums, setPremiums] = useState([]);

  const [policyFeeType, setPolicyFeeType] = useState('op0');
  const [welcomeBonus, setWelcomeBonus] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [showTransactions, setShowTransactions] = useState(false);
  const [formattedTransactions, setFormattedTransactions] = useState([]);

  const [message, setMessage] = useState(
    'You will not be able to modify details after leaving this page!'
  );
  const [disableConfirm, setDisableConfirm] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isUploadOpen, onOpen: onUploadOpen, onClose: onUploadClose } = useDisclosure();

  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);

  const selectNumUnit = (index, num) => {
    let newFunds = funds;
    let x = parseFloat(num);
    if (isNaN(x)) {
      x = '0';
    } else {
      x = num.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    }
    newFunds[index].num = x;
    setFunds([...newFunds]);
  };

  const selectFund = (index, id) => {
    let newFunds = funds;
    newFunds[index].id = id;
    setFunds([...newFunds]);
  };

  const getFirstUnselectedFundId = (arr) => {
    const ids = arr.map((t) => parseInt(t.id));
    let i = 0;
    for (; i < fundOptions.length; i++) {
      if (!ids.includes(fundOptions[i])) {
        return fundOptions[i];
      }
    }
    return 0;
  };

  const addFund = () => {
    if (funds.length >= fundOptions.length) {
      return;
    }
    let newFund = new ProductInfo(getFirstUnselectedFundId(funds), 0.0);
    let newFunds = funds.concat(newFund);
    setFunds([...newFunds]);
  };

  const removeFund = () => {
    if (funds.length <= 1) {
      return;
    }
    funds.pop();
    setFunds([...funds]);
  };

  const addPremium = () => {
    if (premiums.length >= fundOptions.length) {
      return;
    }
    let newPremium = new ProductInfo(getFirstUnselectedFundId(premiums), 0.0);
    let newPremiums = premiums.concat(newPremium);
    setPremiums([...newPremiums]);
  };

  const removePremium = () => {
    if (premiums.length <= 1) {
      return;
    }
    premiums.pop();
    setPremiums([...premiums]);
  };

  const selectAllocation = (index, allocation) => {
    let newPremiums = premiums;
    let x = parseFloat(allocation);
    if (isNaN(x)) {
      x = '0';
    } else {
      x = allocation.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    }
    newPremiums[index].num = x;
    setPremiums([...newPremiums]);
  };

  const selectPremiumFund = (index, id) => {
    let newPremiums = premiums;
    newPremiums[index].id = id;
    setPremiums([...newPremiums]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFundIdsWithAppendedNullOption();
      setFundOptions(res);
      setFunds([new ProductInfo(0, 0.0)]);
      setPremiums([new ProductInfo(0, 0.0)]);
    };
    fetchData();
  }, []);

  const handleAmount = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setAmount(0);
    } else {
      setAmount(x);
    }
  };

  const handleProductName = (event) => {
    setProductName(event.target.value);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handlePremiumAmount = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setPremiumAmount(0);
    } else {
      setPremiumAmount(x);
    }
  };

  const handlePremiumType = (event) => {
    setPremiumType(event.target.value);
  };

  const handlePolicyFeeType = (event) => {
    setPolicyFeeType(event.target.value);
  };

  const handleWelcomeBonus = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setWelcomeBonus(0);
    } else {
      setWelcomeBonus(x);
    }
  };

  const handleSubmit = () => {
    if (premiumAmount <= 0) {
      toastError('Premium cannot be zero');
      setDisableConfirm(true);
    } else if (amount < 0) {
      toastError('Premium paid thus far cannot be less than zero');
      setDisableConfirm(true);
    } else if (productName === '') {
      toastError('Product name cannot be empty');
      setDisableConfirm(true);
    } else {
      let s = new Set(funds.map((e) => e.id));
      if (s.size < funds.length) {
        toastError('Please do not enter duplicate fund entries');
        setDisableConfirm(true);
      } else {
        let w = funds.filter((e) => {
          return parseInt(e.id) > 0;
        }).length;
        w = w === undefined ? 0 : w;
        if (s.size > w) {
          toastError('Please ensure fund entries are properly selected');
          setDisableConfirm(true);
        } else {
          s = new Set(premiums.map((e) => e.id));
          if (s.size < premiums.length) {
            toastError('Please do not enter duplicate premium entries');
            setDisableConfirm(true);
          } else {
            let sum = premiums.reduce((acc, e) => acc + parseFloat(e.num), 0);
            if (sum < 99.99 || sum > 100.01) {
              toastError('The sum of the allocation must be 100%');
              setDisableConfirm(true);
            } else if (startDate === '') {
              toastError('Please select a date');
              setDisableConfirm(true);
            } else if (amount === 0) {
              toastError('Amount paid thus far is zero, are you sure?');
              setDisableConfirm(false);
            } else if (startDate === new Date().toISOString().substring(0, 10)) {
              toastError('The start date selected is today, are you sure?');
              setDisableConfirm(false);
            } else {
              toastError('You will not be able to modify details after leaving this page!');
              setDisableConfirm(false);
            }
          }
        }
      }
    }
    onOpen();
  };

  const handleCreateProduct = async () => {
    if (formattedTransactions.length === 0) {
      toastError('Please provide any transaction!');
      onClose();
      return;
    }

    let apportionment = premiums.reduce(function (map, obj) {
      map[obj.id] = parseFloat(obj.num);
      return map;
    }, {});

    let balances = funds.reduce(function (map, obj) {
      map[obj.id] = parseFloat(obj.num);
      return map;
    }, {});

    try {
      await createExistingProduct(
        productName,
        amount,
        premiumAmount,
        premiumType,
        startDate,
        apportionment,
        balances,
        accountId,
        policyFeeType,
        welcomeBonus
      ).then((product) => {
        toastSuccess('Product is created!');
        handleCreateTransactions(product.ID, product.clientId);
      });
    } catch (err) {
      if (err === 'No such client') {
        toastError('Please Generate Account ID');
      } else {
        toastError(err);
      }
      throw err;
    }
  };

  const handleCreateTransactions = async (productId, clientId) => {
    try {
      await createTransactions(productId, clientId, formattedTransactions);
      toastSuccess('Transactions is created');
      window.location.reload();
    } catch (err) {
      console.error('Error creating transactions:', err);
      if (clientId) {
        await removeClient(clientId);
      }
      toastError(err.message || 'Error creating transactions');
    }
  };

  const onUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const htmlContent = event.target.result;

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      const policyProductTableRows = Array.from(doc.querySelectorAll('td'))
        .find((cell) => cell.textContent === 'Policy Product Investment Account Record')
        .parentElement.nextElementSibling.querySelectorAll('tr');
      const dataRows = Array.from(policyProductTableRows);
      dataRows.shift();

      const startDateFromHtml = Array.from(doc.querySelectorAll('td'))
        .find((el) => el.textContent.trim() === 'Cover Start Date')
        .nextElementSibling.textContent.trim();
      const premiumFrequency = Array.from(doc.querySelectorAll('td'))
        .find((el) => el.textContent === 'Payment Frequency')
        .nextElementSibling.textContent.trim();
      const premiumCell = Array.from(doc.querySelectorAll('td'))
        .find((cell) => cell.textContent.trim() === 'Next Annual Premium')
        .nextElementSibling?.textContent.trim();
      const transactionsFromHtml = Array.from(doc.querySelectorAll('td'))
        .find((el) => el.textContent === 'Fund Transaction Record')
        .closest('tr')?.nextElementSibling;
      const transactionsArray = Array.from(transactionsFromHtml.querySelectorAll('tr'));
      transactionsArray.shift();

      const transactionsMapped = transactionsArray.map((el, index) => {
        const tds = el.querySelectorAll('td');

        const applicationType = tds[1]?.textContent.slice(5) ?? '';

        let fundCode = parseNumberOrZero(tds[2]?.textContent).toString();
        if (fundCode.length === 1) {
          fundCode = '0' + fundCode;
        }

        const amount = parseNumberOrZero(
          cleanTextContent(tds[3]?.textContent ?? '0').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const units = parseNumberOrZero(
          cleanTextContent(tds[4]?.textContent ?? '0').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const price = parseNumberOrZero(
          cleanTextContent(tds[5]?.textContent ?? '0').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const runDate = tds[6]?.textContent ?? '';
        const priceEffectiveDate = tds[7]?.textContent ?? '';
        const balanceUnits = parseNumberOrZero(
          cleanTextContent(tds[8]?.textContent ?? '0').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const type = 'imported';
        return {
          type,
          fundCode,
          amount,
          units,
          price,
          runDate,
          priceEffectiveDate,
          balanceUnits,
          applicationType
        };
      });

      const parsedFundsData = dataRows
        .map((row) => {
          const cells = row.querySelectorAll('td');

          const fundName = cleanNumbersContent(cells[0]?.textContent);
          const apportionment = parseNumberOrZero(
            cleanTextContent(cells[5]?.textContent).replace(/,/g, '')
          ).toFixed(2);
          const amount = parseNumberOrZero(
            cleanTextContent(cells[1]?.textContent).replace(/,/g, '')
          ).toFixed(2);
          const matchingFund = productFundsBase.find((fund) => fund.Name === fundName);

          if (matchingFund) {
            return {
              fund: { id: String(matchingFund.ID), num: amount || null },
              premium: { id: String(matchingFund.ID), num: apportionment || null }
            };
          }

          return null;
        })
        .filter((data) => data !== null);

      const funds = parsedFundsData.map((data) => data.fund).filter((fund) => fund.num !== null);
      const premiums = parsedFundsData
        .map((data) => data.premium)
        .filter((premium) => premium.num !== null);

      setFunds(funds);
      setPremiums(premiums);
      setPremiumType(premiumFrequency === 'Yearly' ? 'annually' : premiumFrequency.toLowerCase());
      setPremiumAmount(parseNumberOrZero(premiumCell.replace(',', '')));
      setStartDate(convertDateToString(startDateFromHtml));
      setTransactions(transactionsMapped);

      let welcomeBonusAmount = 0;
      transactionsArray.forEach((el) => {
        const tds = el.querySelectorAll('td');
        const type = tds[1]?.textContent ?? '';
        const amount = parseNumberOrZero(cleanTextContent(tds[3]?.textContent).replace(/,/g, ''));
        if (type.includes('Welcome Bonus')) {
          welcomeBonusAmount += amount;
        }
      });
      let netInvestmentAmount = 0;
      transactionsArray.forEach((el) => {
        const tds = el.querySelectorAll('td');
        const type = tds[1]?.textContent ?? '';
        const amount = parseNumberOrZero(cleanTextContent(tds[3]?.textContent).replace(/,/g, ''));
        if (type.includes('Net Investment Premium')) {
          netInvestmentAmount += amount;
        }
      });
      const premiumPaidTillDate = Array.from(doc.querySelectorAll('td')).find(
        (el) => el.textContent === 'Total Investment Value'
      );

      setAmount(netInvestmentAmount);
      setWelcomeBonus(welcomeBonusAmount);
    };

    reader.readAsText(file);
    onUploadClose();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onUpload,
    noClick: true,
    accept: 'text/html'
  });

  useEffect(() => {
    const formatTransactions = (transactions) => {
      return transactions.map((transaction) => {
        const [day, month, year] = transaction.runDate.split('/');
        const runDate = new Date(`${year}-${month}-${day}T00:00:00`).toJSON();

        const [pDay, pMonth, pYear] = transaction.priceEffectiveDate.split('/');
        const priceEffectiveDate = new Date(`${pYear}-${pMonth}-${pDay}T00:00:00`).toJSON();

        const amount = parseFloat(transaction.amount);
        const balanceUnits = parseFloat(transaction.balanceUnits);
        const fundCode = parseFloat(transaction.fundCode);
        const price = parseFloat(transaction.price);
        const units = parseFloat(transaction.units);
        const startDate = transaction.startDate;

        return {
          ...transaction,
          amount,
          balanceUnits,
          fundCode,
          price,
          units,
          runDate,
          priceEffectiveDate,
          startDate
        };
      });
    };

    setFormattedTransactions(formatTransactions(transactions));
  }, [transactions]);

  useEffect(() => {
    if (transactions.length === 0) {
      setDisableConfirm(true);
    }
  },[transactions])

  return (
    <div style={{ width: '100%' }}>
      <Card direction="column" h="160px" px="20px" mb="20px" border={`1px solid ${borderColor}`}>
        <Flex justify="space-between" mb="20px" flexDirection="column">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Input product transaction history
          </Text>
          <Text
            color={helperTextColor}
            fontSize="16px"
            fontWeight="500"
            lineHeight="100%"
            padding="0.625rem 0"
          >
            DISCLAIMER: DO NOT PROVIDE ANY SENSITIVE INFORMATION
          </Text>
          <Flex justify={'space-evenly'} mt={'20px'}>
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={() => setShowTransactions(true)}
            >
              Import manually
            </Button>
            <Button
              color={buttonTextColor}
              fontSize="16px"
              bg={bgButton}
              _hover={bgHover}
              onClick={() => onUploadOpen()}
            >
              Import via file upload
            </Button>
          </Flex>
        </Flex>
      </Card>

      <Card
        direction="column"
        w="100%"
        pt="20px"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        border={`1px solid ${borderColor}`}
        mb="20px"
      >
        <Flex px="25px" justify="space-between" mb="20px" flexDirection="column">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Input Existing Client Product Details
          </Text>
          <Text
            color={helperTextColor}
            fontSize="16px"
            fontWeight="500"
            lineHeight="100%"
            padding="0.625rem 0"
          >
            DISCLAIMER: DO NOT PROVIDE ANY SENSITIVE INFORMATION
          </Text>
        </Flex>
        <AddFund
          cardColor={cardColor}
          textColor={textColor}
          inputBg={inputBg}
          inputText={inputText}
          handleAmount={handleAmount}
          amount={amount}
          handleStartDate={handleStartDate}
          startDate={startDate}
          handleProductName={handleProductName}
          productName={productName}
          funds={funds}
          fundOptions={fundOptions}
          addFund={addFund}
          removeFund={removeFund}
          selectFund={selectFund}
          selectNumUnit={selectNumUnit}
          handlePremiumAmount={handlePremiumAmount}
          premiumAmount={premiumAmount}
          handlePremiumType={handlePremiumType}
          premiumType={premiumType}
          premiums={premiums}
          addPremium={addPremium}
          removePremium={removePremium}
          selectAllocation={selectAllocation}
          selectPremiumFund={selectPremiumFund}
          handlePolicyFeeType={handlePolicyFeeType}
          policyFeeType={policyFeeType}
          handleWelcomeBonus={handleWelcomeBonus}
          welcomeBonus={welcomeBonus}
        />
      </Card>

      <TransactionTable
        transactions={transactions}
        bgButton={bgButton}
        bgHover={bgHover}
        textColor={textColor}
        helperTextColor={helperTextColor}
        showTransactions={showTransactions}
        setTransactions={setTransactions}
        buttonTextColor={buttonTextColor}
        inputBg={inputBg}
        inputText={inputText}
        borderColor={borderColor}
        setDisableConfirm={setDisableConfirm}
      />

      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => handleCreateProduct()}
        message={message}
        disabled={disableConfirm}
        buttonText="Continue editing"
      />

      <Flex px="25px" justify="center" width="100%" margin="20px 0">
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          _hover={bgHover}
          onClick={handleSubmit}
          disabled={disableConfirm}
        >
          Confirm
        </Button>
      </Flex>

      <UploadModal
        isOpen={isUploadOpen}
        onClose={onUploadClose}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        openFileDialog={open}
        textColor={textColor}
        helperTextColor={helperTextColor}
      />
    </div>
  );
}
