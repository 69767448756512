/* eslint-disable */
import { Button, Flex, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useState } from 'react';
import { addWhitelist } from 'service/api';
import FileUpload from './FileUpload';

export default function UploadBox(props) {
  const [file, setFile] = useState(null);

  const onFileAccepted = (upload) => {
    setFile(upload);
  };

  const handleUpload = async () => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const emails = text.split(/[,|\n|\r|\t]/).filter((email) => email.trim() !== '');
      try {
        await addWhitelist(emails);
        window.location.reload(true);
      } catch (err) {
        alert(err);
      }
    };
    reader.readAsText(file);
  };

  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const bgButton = useColorModeValue('', '#47ABFF');
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');

  return (
    <Card>
      <Flex justifyContent="center" flexDir={'column'}>
        <FileUpload onFileAccepted={onFileAccepted} />
        {file && <Text marginTop="16px">Uploaded: {file.path}</Text>}
        <Button
          bg={bgButton}
          color={buttonTextColor}
          _hover={bgHover}
          marginTop="16px"
          disabled={!file}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Flex>
    </Card>
  );
}
