import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  useColorModeValue
} from '@chakra-ui/react';

import { css } from '@emotion/react';
import { isFloat } from 'utils/numbers';

import calendarIconWhite from 'assets/img/icons/calendar.png';
import calendarIconBlack from 'assets/img/icons/calendarBlack.png';
import { formatFundName } from 'service/api';

export class ProductInfo {
  constructor(id, num) {
    this.id = id;
    this.num = num;
  }
}

export default function AddProduct(props) {
  const {
    cardColor,
    textColor,
    inputBg,
    inputText,
    handlePremium,
    premium,
    handlePremiumDate,
    premiumDate,
    handlePremiumType,
    premiumType,
    handlePremiumName,
    premiumName,
    funds,
    fundOptions,
    addFund,
    removeFund,
    selectFund,
    selectAllocation,
    handlePolicyFeeType,
    policyFeeType,
    handleWelcomeBonus,
    welcomeBonus
  } = props;

  const bgButton = useColorModeValue('#5CE1E6', '#26acd1');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const inputTextPlaceholder = useColorModeValue('secondaryGray.600', 'whiteAlpha.600');
  const calendarIcon = useColorModeValue(`${calendarIconBlack}`, `${calendarIconWhite}`);

  const filterFundOptions = (currentId) => {
    const ids = funds.map((t) => parseInt(t.id));
    const filtered = fundOptions.filter(
      (o) => parseInt(o) == currentId || !ids.includes(parseInt(o))
    );
    return filtered;
  };

  return (
    <Flex px="25px" justify="space-between" mb="20px">
      <Box direction="column" w="100%" px="0px" margin="8px" borderRadius="8px" bg={cardColor}>
        <Flex px="25px" justify="space-between">
          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px 0"
            padding="8px 0"
            borderRadius="8px"
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Amount
            </Text>

            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="100"
                onChange={handlePremium}
                value={premium}
              />
            </InputGroup>
          </Box>

          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px"
            padding="8px"
            borderRadius="8px"
            css={css`
              input::-webkit-datetime-edit-day-field:focus,
              input::-webkit-datetime-edit-month-field:focus,
              input::-webkit-datetime-edit-year-field:focus {
                color: ${inputText};
                outline: none;
              }
              input[type='date']:focus {
                color: ${inputTextPlaceholder};
                outline: none;
              }
            `}
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Date
            </Text>
            <Input
              variant="date"
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              type="date"
              onChange={handlePremiumDate}
              value={premiumDate}
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              css={css`
                ::-webkit-calendar-picker-indicator {
                  background: url(${calendarIcon}) center/80% no-repeat;
                  color: black;
                }
              `}
            />
          </Box>
        </Flex>

        <Flex px="25px" justify="space-between">
          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px 0"
            padding="8px 0"
            borderRadius="8px"
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Input Product Name
            </Text>

            <Input
              variant="date"
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder="PRODUCT NAME"
              onChange={handlePremiumName}
              value={premiumName}
            />
          </Box>

          <Box direction="column" w="100%" px="0px" margin="8px" padding="8px" borderRadius="8px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Premium Frequency
            </Text>
            <Select
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              mt="5px"
              onChange={handlePremiumType}
              value={premiumType}
            >
              <option value="monthly">MONTHLY</option>
              <option value="annually">ANNUALLY</option>
              <option value="lumpsum">LUMP-SUM</option>
            </Select>
          </Box>
        </Flex>
        <Flex px="25px" justify="space-between">
          <Box
            direction="column"
            w="100%"
            px="0px"
            margin="8px 0"
            padding="8px 0"
            borderRadius="8px"
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Total Welcome Bonus
            </Text>

            <InputGroup>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="100"
                onChange={handleWelcomeBonus}
                value={welcomeBonus}
              />
            </InputGroup>
          </Box>

          <Box direction="column" w="100%" px="0px" margin="8px" padding="8px" borderRadius="8px">
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Policy Fee Deduction Type
            </Text>
            <Select
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              borderRadius="30px"
              fontSize="sm"
              mt="5px"
              marginBottom="0.625rem"
              onChange={handlePolicyFeeType}
              value={policyFeeType}
            >
              <option value="op0">OP - 0</option>
              <option value="np1">NP - 1</option>
              <option value="np2">NP - 2</option>
            </Select>
            <Text color={textColor} fontSize="12px">
              {policyFeeType === 'op0'
                ? 'Old Plan: 2.5% and 0% from 11th year'
                : policyFeeType === 'np1'
                ? 'New Plan 1: 2.5% and 0.6% from 11th year'
                : 'New Plan 2: 1.5% and 0.6% from 16th year'}
            </Text>
          </Box>
        </Flex>
        {funds.map((element, index) => (
          <Flex px="25px" justify="space-between" key={index}>
            <Box
              direction="column"
              w="100%"
              px="0px"
              margin="8px 0"
              padding="8px 0"
              borderRadius="8px"
            >
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                {`Fund ${index + 1}`}
              </Text>
              <Select
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                borderRadius="30px"
                fontSize="sm"
                value={element.id}
                onChange={(e) => selectFund(index, e.target.value)}
              >
                {filterFundOptions(element.id).map((fundOption) => {
                  return (
                    <option value={fundOption} key={fundOption}>
                      {formatFundName(fundOption)}
                    </option>
                  );
                })}
              </Select>
            </Box>

            <Box direction="column" w="100%" px="0px" margin="8px" padding="8px" borderRadius="8px">
              <Text
                color={textColor}
                fontSize="16px"
                fontWeight="700"
                lineHeight="100%"
                marginBottom="0.625rem"
              >
                Allocation (%)
              </Text>

              <Input
                fontSize="sm"
                bg={inputBg}
                color={inputText}
                fontWeight="500"
                _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                borderRadius="30px"
                placeholder="e.g. % of Allocation"
                value={element.num}
                onChange={(e) => selectAllocation(index, e.target.value)}
                isInvalid={!isFloat(element.num)}
              />
            </Box>
          </Flex>
        ))}

        {funds.length < 32 && (
          <Flex my="25px" justify="center" width="100%">
            <Button
              color="black"
              fontSize="16px"
              mx="8px"
              bg={bgButton}
              _hover={bgHover}
              onClick={addFund}
              disabled={funds.length >= fundOptions.length}
            >
              +
            </Button>
            <Button
              variant={'outline'}
              fontSize="16px"
              mx="8px"
              onClick={removeFund}
              disabled={funds.length <= 1}
            >
              -
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
