export const columnsClientsOverview = [
  {
    Header: 'UID',
    accessor: 'hash'
  },
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'START DATE',
    accessor: 'start_date'
  },
  {
    Header: 'TOTAL INVESTED',
    accessor: 'total_invested'
  },
  {
    Header: 'AUM',
    accessor: 'aum'
  },
  {
    Header: 'Overall XIRR',
    accessor: 'xirr'
  },
  {
    Header: 'Overall ROI',
    accessor: 'roi'
  }
];

export const columnsClientTransactions = [
  {
    Header: 'CLIENT',
    accessor: 'client'
  },
  {
    Header: 'TYPE',
    accessor: 'type'
  },
  {
    Header: 'AMOUNT',
    accessor: 'amount'
  },
  {
    Header: 'DATE',
    accessor: 'date'
  }
];

export const columnsPerformingFund = [
  {
    Header: 'RANK',
    accessor: 'rank'
  },
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'CHANGE',
    accessor: 'change'
  }
];

export const columnsAlert = [
  {
    Header: 'NAME',
    accessor: 'name'
  },
  {
    Header: 'PRICE',
    accessor: 'price'
  },
  {
    Header: 'DATE CREATED',
    accessor: 'date'
  },
  {
    Header: 'TRIGGERED DATE',
    accessor: 'triggered_date'
  }
];
