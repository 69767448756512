import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Icon,
    Input,
    Select,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import {
    MdArrowDownward,
    MdArrowUpward,
    MdCancel,
    MdCheckCircle,
    MdDelete,
    MdEdit
} from 'react-icons/md';
import { formatFundName } from 'service/api';

export default function ConfigureAlerts(props) {
  const {
    alerts,
    deleteAlert,
    toggleAlert,
    toggleEdit,
    toggleLive,
    editFundId,
    editFundPrice,
    fundOptions
  } = props;

  const cardColor = useColorModeValue('#DFDFDF', '#162744');
  const inputBg = useColorModeValue('#DFDFDF', '#162744');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');

  const filterFundOptions = (currentId) => {
  const ids = [...new Set(alerts.map((t) => parseInt(t.fundId)))];
  
  const filtered = fundOptions.filter(
    (o) => parseInt(o) == currentId || !ids.includes(parseInt(o))
  );

  const combined = [...ids, ...filtered.filter((id) => !ids.includes(id))];

  return combined;
};


  return (
    <Card
      direction="column"
      w="100%"
      px="20px"
      pt="20px"
      pb="0"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      bg={cardColor}
      border={`1px solid ${borderColor}`}
    >
      <Flex direction="column" mx="6px">
        <Grid
          templateColumns="repeat(8, 1fr)"
          fontWeight="bold"
          color="gray.400"
          fontSize="12px"
          paddingY="4px"
          borderBottom="1px solid"
          borderBottomColor="gray.700"
          marginBottom="20px"
        >
          <Text>NO.</Text>
          <GridItem colSpan={2}>
            <Text>FUND NAME</Text>
          </GridItem>

          <Text>PRICE</Text>
          <Text>SET ALERT TYPE</Text>
          <Text>EDIT</Text>
          <Text>DELETE</Text>
          <Text>LIVE STATUS</Text>
        </Grid>
        {alerts.map((value, index) => (
          <Grid templateColumns="repeat(8, 1fr)" spacing={10} key={index} my="6px">
            <Text fontWeight="bold" mt="6px">
              {index + 1}
            </Text>
            <GridItem colSpan={2} paddingRight="24px">
              {value.edit ? (
                <Select
                  bg={inputBg}
                  color={inputText}
                  fontWeight="500"
                  borderRadius="30px"
                  fontSize="sm"
                  value={value.fundId}
                  onChange={(e) => editFundId(index, e.target.value)}
                  border={`1px solid`}
                  borderColor={borderColor}
                >
                  {filterFundOptions(value.fundId).map((fundOption) => {
                    return (
                      <option value={fundOption} key={fundOption}>
                        {formatFundName(fundOption)}
                      </option>
                    );
                  })}
                </Select>
              ) : (
                <Text fontWeight="bold" mt="6px">
                  {formatFundName(value.fundId)}
                </Text>
              )}
            </GridItem>
            <GridItem paddingRight="24px">
              {value.edit ? (
                <Input
                  variant="date"
                  fontSize="sm"
                  bg={inputBg}
                  color={inputText}
                  border={`1px solid ${borderColor}`}
                  fontWeight="500"
                  _placeholder={{ color: 'gray.400', fontSize: '14px' }}
                  borderRadius="30px"
                  placeholder={'Price'}
                  value={value.price}
                  onChange={(event) => editFundPrice(index, event.target.value)}
                />
              ) : (
                <Text fontWeight="bold" mt="6px">
                  $ {value.price}
                </Text>
              )}
            </GridItem>
            <Box>
              <Button p="0" bg="transparent" onClick={() => toggleAlert(index)}>
                {value.alertType ? (
                  <Icon as={MdArrowUpward} h="20px" w="20px" color="green" />
                ) : (
                  <Icon as={MdArrowDownward} h="20px" w="20px" color="red" />
                )}
              </Button>
            </Box>
            <Box>
              <Button p="0" bg="transparent" onClick={() => toggleEdit(index)}>
                {!value.live ? <Icon as={MdEdit} h="20px" w="20px" /> : <></>}
              </Button>
            </Box>
            <Box>
              <Button p="0" bg="transparent" onClick={() => deleteAlert(index)}>
                {!value.live ? <Icon as={MdDelete} h="20px" w="20px" /> : <></>}
              </Button>
            </Box>
            <Box>
              <Button p="0" bg="transparent" onClick={() => toggleLive(index)}>
                {value.live ? (
                  <Icon as={MdCheckCircle} h="20px" w="20px" color="green" />
                ) : (
                  <Icon as={MdCancel} h="20px" w="20px" color="red" />
                )}
              </Button>
            </Box>
          </Grid>
        ))}
      </Flex>
    </Card>
  );
}
