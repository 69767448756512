import React from 'react';
import ReactApexChart from 'react-apexcharts';

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.stroke = { show: false };

    this.state = {
      chartData: [],
      chartOptions: {
        plotOptions: {
          pie: {
            type: 'donut',
            donut: {
              size: '35px'
            }
          }
        }
      }
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: this.props.chartOptions
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.chartData !== this.props.chartData ||
      prevProps.chartOptions !== this.props.chartOptions
    ) {
      this.updateChartData(this.props.chartData, this.props.chartOptions);
    }
  }

  updateChartData(chartData, chartOptions) {
    this.setState({
      chartData,
      chartOptions
    });
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="donut"
        width="300px"
        height="300px"
      />
    );
  }
}

export default PieChart;
