// Chakra Imports
import { Button, Icon, useColorMode, useColorModeValue } from '@chakra-ui/react';
// Custom Icons
import { IoMdMoon, IoMdSunny } from 'react-icons/io';

export default function FixedPlugin(props) {
  const { position = 'fixed', height = '60px', width = '60px', left, top, ...rest } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const bgButton = useColorModeValue('#47abff', '#47ABFF');

  return (
    <Button
      {...rest}
      h={height}
      w={width}
      bg={bgButton}
      zIndex="99"
      position={position}
      left={left}
      top={top}
      border="1px solid"
      borderColor={bgButton}
      borderRadius="50px"
      onClick={toggleColorMode}
      display="flex"
      p="0px"
      px="0px"
      align="center"
      justify="center"
      shadow={'none'}
    >
      <Icon
        px="0"
        h="24px"
        w="24px"
        color="white"
        as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
      />
    </Button>
  );
}
