import { Box, Text } from '@chakra-ui/react';
import logoDark from 'assets/img/auth/logo-dark.png';
import { useEffect, useState } from 'react';

const ResponsiveMessage = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box bg="#47abff">
      {isMobile ? (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          backgroundColor="#47abff"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          zIndex="9999"
          gap="100px"
        >
            <Box>
             <img src={logoDark} alt="logo" width="200px" height="200px" />
            </Box>
          <Text fontSize="4xl" color="#DFDFDF" textAlign="center">
          The experience is not optimised for mobile devices yet
          </Text>
        </Box>
      ) : (
        children 
      )}
    </Box>
  );
};

export default ResponsiveMessage;
