import {
    Box,
    Button,
    Checkbox,
    Flex,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure
} from '@chakra-ui/react';

// Custom components
import { css } from '@emotion/react';
import helpInfoModal from 'assets/img/helpInfo.png';
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { createClient, createExistingProduct, getSelfAgentId, removeClient } from 'service/api';

import calendarIconWhite from 'assets/img/icons/calendar.png';
import calendarIconBlack from 'assets/img/icons/calendarBlack.png';
import UploadModal from 'components/uploadModal/UploadModal';
import { useDropzone } from 'react-dropzone';
import toast, { Toaster } from 'react-hot-toast';
import {
    createTransactions,
    editClient,
    editProduct,
    editTransactions,
    getAgentClientsWithStats,
    getClient,
    getFundIdsWithAppendedNullOption,
    getTransactions,
    funds as productFundsBase
} from 'service/api';
import { parseNumberOrZero } from 'utils/numbers';
import { cleanNumbersContent, cleanTextContent, convertDateToString } from 'utils/string';
import { ProductInfo } from 'views/agent/createClientProfile/components/AddProduct';
import ConfirmModal from '../../../../components/confirmModal/ConfirmModal';
import AddFund from './AddFund';
import TransactionTable from './TransactionTable';

export default function ImportClientForm(props) {
  const { productInfo } = props;
  const MAX_CLIENT = 123456789;
  const textColor = useColorModeValue('white.100', 'white.100');
  const helperTextColor = useColorModeValue('secondaryGray.900', 'whiteAlpha.600');
  const cardColor = useColorModeValue('#DFDFDF', '#162744');
  const inputBg = useColorModeValue('#DFDFDF', '#162744');
  const inputText = useColorModeValue('#162744', '#DFDFDF');
  const inputTextPlaceholder = useColorModeValue('secondaryGray.600', 'whiteAlpha.600');
  const bgHover = useColorModeValue({ bg: 'blue.400' }, { bg: 'blue.400' });
  const bgButton = useColorModeValue('#47abff', '#47ABFF');
  const buttonTextColor = useColorModeValue('#DFDFDF', '#162744');
  const borderColor = useColorModeValue('#162744', '#DFDFDF');
  const calendarIcon = useColorModeValue(`${calendarIconBlack}`, `${calendarIconWhite}`);
  const regex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [amount, setAmount] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [productName, setProductName] = useState('');
  const [checkedGender, setCheckedGender] = useState(true);
  const [checkedSmoker, setCheckedSmoker] = useState(false);
  const [accountId, setAccountId] = useState(MAX_CLIENT);
  const [funds, setFunds] = useState([]);
  const [fundOptions, setFundOptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showTransactions, setShowTransactions] = useState(false);
  const [formattedTransactions, setFormattedTransactions] = useState([]);
  const [premiumAmount, setPremiumAmount] = useState(0);
  const [premiumType, setPremiumType] = useState('monthly');
  const [premiums, setPremiums] = useState([]);
  const [policyFeeType, setPolicyFeeType] = useState(null);
  const [welcomeBonus, setWelcomeBonus] = useState(0);
  const [disableButton, setDisableButton] = useState(null);
  const [message, setMessage] = useState(
    'You will not be able to modify details after leaving this page!'
  );
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [hasAutomaticTransaction, setHasAutomaticTransaction] = useState(false);

  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();

  const {
    isOpen: isAddProductOpen,
    onOpen: onAddProductOpen,
    onClose: onAddProductClose
  } = useDisclosure();
  const toastSuccess = (message) => toast.success(message);
  const toastError = (message) => toast.error(message);
  const {
    isOpen: isHtmlUploadOpen,
    onOpen: onHtmlUploadOpen,
    onClose: onHtmlUploadClose
  } = useDisclosure();

  const agentId = getSelfAgentId();

  const selectNumUnit = (index, num) => {
    let newFunds = funds;
    let x = parseFloat(num);
    if (isNaN(x)) {
      x = '0';
    } else {
      x = num.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    }
    newFunds[index].num = x;
    setFunds([...newFunds]);
  };

  const selectFund = (index, id) => {
    let newFunds = funds;
    newFunds[index].id = id;
    setFunds([...newFunds]);
  };
  const getFirstUnselectedFundId = (arr) => {
    const ids = arr.map((t) => parseInt(t.id));
    let i = 0;
    for (; i < fundOptions.length; i++) {
      if (!ids.includes(fundOptions[i])) {
        return fundOptions[i];
      }
    }
    return 0;
  };

  const addFund = () => {
    if (funds.length >= fundOptions.length) return;

    let newFund = new ProductInfo(getFirstUnselectedFundId(funds), 0.0);
    setFunds((prevFunds) => [...prevFunds, newFund]);
  };

  const removeFund = () => {
    if (funds.length <= 1) return;

    setFunds((prevFunds) => prevFunds.slice(0, -1));
  };

  const addPremium = () => {
    if (premiums.length >= fundOptions.length) return;

    let newPremium = new ProductInfo(getFirstUnselectedFundId(premiums), 0.0);
    setPremiums((prevPremiums) => [...prevPremiums, newPremium]);
  };

  const removePremium = () => {
    if (premiums.length <= 1) return;

    setPremiums((prevPremiums) => prevPremiums.slice(0, -1));
  };

  const selectAllocation = (index, allocation) => {
    let newPremiums = premiums;
    let x = parseFloat(allocation);
    if (isNaN(x)) {
      x = '0';
    } else {
      x = allocation.replace(/^0+(?=\d)/, '').replace(/[^0-9.]/g, '');
    }
    newPremiums[index].num = x;
    setPremiums([...newPremiums]);
  };

  const selectPremiumFund = (index, id) => {
    let newPremiums = premiums;
    newPremiums[index].id = id;
    setPremiums([...newPremiums]);
  };

  const convertRunDate = (inputRunDate) => {
    if (!inputRunDate) {
      return null;
    }
    let formattedRunDate;
    if (regex.test(inputRunDate)) {
      const [year, month, day] = inputRunDate.split('-');
      formattedRunDate = new Date(`${year}-${month}-${day}T00:00:00`).toJSON();
    } else {
      const [day, month, year] = inputRunDate.split('/');
      formattedRunDate = new Date(`${year}-${month}-${day}T00:00:00`).toJSON();
    }
    return formattedRunDate;
  };

  const convertPriceEffectiveDate = (inputPriceEffectiveDate) => {
    if (!inputPriceEffectiveDate) return null;
    let formattedPriceEffectiveDate;
    if (regex.test(inputPriceEffectiveDate)) {
      const [pYear, pMonth, pDay] = inputPriceEffectiveDate.split('-');
      formattedPriceEffectiveDate = new Date(`${pYear}-${pMonth}-${pDay}T00:00:00`).toJSON();
    } else {
      const [pDay, pMonth, pYear] = inputPriceEffectiveDate.split('/');
      formattedPriceEffectiveDate = new Date(`${pYear}-${pMonth}-${pDay}T00:00:00`).toJSON();
    }
    return formattedPriceEffectiveDate;
  };

  useEffect(() => {
    const hasAutomatic = transactions.some((transaction) => transaction.type === 'automatic');
    setHasAutomaticTransaction(hasAutomatic);
  }, [transactions]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getFundIdsWithAppendedNullOption();
      setFundOptions(res);
      if (!productInfo) {
        setFunds([new ProductInfo(0, 0.0)]);
        setPremiums([new ProductInfo(0, 0.0)]);
      }
    };
    fetchData();
  }, []);

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleBirthday = (event) => {
    setBirthday(event.target.value);
  };

  const handleAmount = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setAmount(0);
    } else {
      setAmount(x);
    }
  };

  const handleProductName = (event) => {
    setProductName(event.target.value);
  };

  const handleStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleCheckMale = (event) => {
    setCheckedGender(event.target.checked);
  };

  const handleCheckFemale = (event) => {
    setCheckedGender(!event.target.checked);
  };

  const handleCheckSmoker = (event) => {
    setCheckedSmoker(event.target.checked);
  };

  const handleCheckNonSmoker = (event) => {
    setCheckedSmoker(!event.target.checked);
  };

  const handlePremiumAmount = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setPremiumAmount(0);
    } else {
      setPremiumAmount(x);
    }
  };

  const handlePremiumType = (event) => {
    setPremiumType(event.target.value);
  };

  const handlePolicyFeeType = (event) => {
    setPolicyFeeType(event.target.value);
  };

  const handleWelcomeBonus = (event) => {
    const x = parseFloat(event.target.value);
    if (isNaN(x)) {
      setWelcomeBonus(0);
    } else {
      setWelcomeBonus(x);
    }
  };

  const validateForm = () => {
    let errorMessage = '';
    let isValid = true;

    const validateField = (field) => {
      switch (field) {
        case 'name':
          if (name.trim() === '') {
            errorMessage = "Please provide the client's name";
            isValid = false;
          }
          break;
        case 'birthday':
          if (!/^\d{4}-\d{2}-\d{2}$/.test(birthday)) {
            errorMessage = 'Please provide a valid birthday';
            isValid = false;
          }
          break;
        case 'amount':
          if (amount <= 0) {
            errorMessage = 'Amount must be greater than zero';
            isValid = false;
          }
          break;
        case 'premiumAmount':
          if (premiumAmount <= 0) {
            errorMessage = 'Premium cannot be zero';
            isValid = false;
          }
          break;
        case 'productName':
          if (productName.trim() === '') {
            errorMessage = 'Product name cannot be empty';
            isValid = false;
          }
          break;
        case 'startDate':
          if (startDate === '') {
            errorMessage = 'Please select a start date';
            isValid = false;
          }
          break;
        case 'funds':
          if (new Set(funds.map((f) => f.id)).size < funds.length) {
            errorMessage = 'Please do not enter duplicate fund entries';
            isValid = false;
          }
          break;
        case 'premiums':
          const sum = premiums.reduce((acc, p) => acc + parseFloat(p.num), 0);
          if (sum < 99.99) {
            errorMessage = 'The sum of the allocation must be 100%';
            isValid = false;
          }
          break;
        case 'transactions':
          if (transactions.length === 0) {
            errorMessage = 'Please provide at least one transaction';
            isValid = false;
          } else {
            const invalidTransaction = transactions.find((transaction) => {
              return (
                !transaction.fundCode ||
                !transaction.amount ||
                !transaction.units ||
                !transaction.price ||
                !transaction.runDate ||
                !transaction.priceEffectiveDate ||
                !transaction.balanceUnits
              );
            });
            if (invalidTransaction) {
              errorMessage = 'Please fill in all fields for each transaction';
              isValid = false;
            }
          }
          break;
        default:
          break;
      }
    };

    [
      'name',
      'birthday',
      'amount',
      'premiumAmount',
      'productName',
      'startDate',
      'funds',
      'premiums',
      'transactions'
    ].forEach(validateField);

    if (isValid) {
      const allFieldsFilled = [
        name.trim(),
        birthday,
        amount,
        premiumAmount,
        productName.trim(),
        startDate,
        funds.length > 0,
        premiums.length > 0,
        transactions.length > 0
      ].every(Boolean);

      if (!allFieldsFilled) {
        errorMessage = 'Please fill in all fields';
        isValid = false;
      }
    }

    setMessage(errorMessage);
    setDisableConfirm(!isValid);
  };

  useEffect(() => {
    validateForm();
  }, [name, birthday, amount, premiumAmount, productName, startDate, funds, premiums]);

  const handleSubmit = () => {
    setMessage('You will not be able to modify details after leaving this page!');
    onConfirmOpen();
  };

  const validateBirthday = (birthday) => {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(birthday)) {
      return -1;
    }
    let parts = birthday.split('-');
    let now = new Date();
    let year = parseInt(parts[0], 10);
    let currentYear = now.getFullYear();
    let month = parts[1][0] === '0' ? parseInt(parts[1][1], 10) : parseInt(parts[1], 10);
    let day = parts[2][0] === '0' ? parseInt(parts[2][1], 10) : parseInt(parts[2], 10);

    if (year >= currentYear) {
      return -1;
    }
    if (currentYear - year < 18 || currentYear - year > 100) {
      return -1;
    }
    if (month < 1 || month > 12) {
      return -1;
    }
    if (day < 1 || day > 31) {
      return -1;
    }
    return 0;
  };

  const checkName = async (name) => {
    const clients = await getAgentClientsWithStats(getSelfAgentId(), 100, 0);
    if (clients.some((client) => client.name === name)) {
      toastError('Name already exists, provide a unique name');
      return false;
    }
    return true;
  };

  const handleCreateClient = async () => {
    if (name === '') {
      toastError("Please provide the client's name");
      onConfirmClose();
      return null;
    } else if (validateBirthday(birthday) !== 0) {
      toastError('Please provide a valid birthday');
      onConfirmClose();
      return null;
    } else {
      const isNameUnique = await checkName(name);
      if (!isNameUnique) {
        return null;
      }

      try {
        const res = await createClient(
          name,
          birthday,
          checkedGender ? 'male' : 'female',
          checkedSmoker,
          agentId
        );
        setAccountId(res['ID']);
        return res;
      } catch (err) {
        alert(err);
        return null;
      }
    }
  };

  const handleEditClient = async () => {
    if (name === '') {
      toastError("Please provide the client's name");
      return null;
    } else if (validateBirthday(birthday) !== 0) {
      toastError('Please provide a valid birthday');
      return null;
    } else {
      const isNameUnique = await checkName(name);
      if (!isNameUnique) {
        return null;
      }
      try {
        const editedClient = await editClient(
          productInfo.clientId,
          name,
          new Date(birthday).toJSON(),
          checkedSmoker,
          checkedGender ? 'male' : 'female'
        );
        return editedClient;
      } catch (err) {
        toastError(err);
        console.error('Error editing client:', err);
        return null;
      }
    }
  };

  const handleCreateProduct = async () => {
    if (transactions.length === 0) {
      toastError('Please provide any transaction');
      onConfirmClose();
    } else {
      let apportionment = premiums.reduce(function (map, obj) {
        map[obj.id] = parseFloat(obj.num);
        return map;
      }, {});

      let balances = funds.reduce(function (map, obj) {
        map[obj.id] = parseFloat(obj.num);
        return map;
      }, {});

      let clientId = null;
      try {
        if (productInfo) {
          const editedClient = await handleEditClient();

          if (editedClient === null) {
            return;
          }

          const editedProduct = await editProduct(
            productInfo.ID,
            productName,
            amount,
            premiumAmount,
            premiumType,
            startDate.split('/').reverse().join('-'),
            apportionment,
            balances,
            editedClient.ID,
            policyFeeType,
            welcomeBonus
          );
          const editedTransactions = await editTransactions(
            productInfo.ID,
            editedClient.ID,
            transactions.map((transaction) => ({
              ...transaction,
              runDate: convertRunDate(transaction.runDate),
              priceEffectiveDate: convertPriceEffectiveDate(transaction.priceEffectiveDate),
              fundCode: +transaction.fundCode,
              balanceUnits: +transaction.balanceUnits,
              amount: +transaction.amount,
              price: +transaction.price,
              units: +transaction.units
            }))
          );
          if (editedTransactions) {
            window.location.reload(true);
          }
          clientId = editedClient.ID;
        } else {
          const clientInfo = await handleCreateClient();
          if (clientInfo === null) {
            return; // Handle the case where client creation fails
          }

          clientId = clientInfo['ID'];
          const res = await createExistingProduct(
            productName,
            amount,
            premiumAmount,
            premiumType,
            startDate,
            apportionment,
            balances,
            clientId,
            policyFeeType,
            welcomeBonus
          );

          toastSuccess('Product is created!');

          return { ...res, clientId };
        }
      } catch (err) {
        toastError(err);
        console.error('Error creating product:', err);
        await removeClient(clientId);

        if (err === 'No such client') {
          toastError('Please Generate Account ID');
        } else {
          toastError(err, 'THIS SHOULD HELP DEBUGGING');
        }

        // Refresh page to clear form whenever an error occurs
        // window.location.reload(true);
      }
    }
  };

  const handleCreateTransactions = async (productId, clientId) => {
    try {
      if (productInfo) {
        const transactionRes = await editTransactions(productId, clientId, formattedTransactions);
        toastSuccess('Transactions are edited!');
      } else {
        const transactionRes = await createTransactions(productId, clientId, formattedTransactions);
        toastSuccess('Transactions are created!');
      }

      const clientPage = `${process.env.PUBLIC_URL}/agent/clients`;
      window.location.replace(clientPage);
    } catch (err) {
      toastError(err.message || 'Error creating transactions');
      console.error('Error creating transactions:', err);
      if (clientId) {
        await removeClient(clientId);
      }
    }
  };

  const onUpload = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const htmlContent = event.target.result;

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      const policyProductTableRows = Array.from(doc.querySelectorAll('td'))
        .find((cell) => cell.textContent === 'Policy Product Investment Account Record')
        .parentElement.nextElementSibling.querySelectorAll('tr');
      const dataRows = Array.from(policyProductTableRows);
      dataRows.shift();

      const startDateFromHtml =
        Array.from(doc.querySelectorAll('td'))
          .find((el) => el.textContent.trim() === 'Cover Start Date')
          .nextElementSibling.textContent.trim() || '0';
      const premiumFrequency =
        Array.from(doc.querySelectorAll('td'))
          .find((el) => el.textContent === 'Payment Frequency')
          .nextElementSibling.textContent.trim() || '0';
      const premiumCell =
        Array.from(doc.querySelectorAll('td'))
          .find((cell) => cell.textContent.trim() === 'Next Annual Premium')
          .nextElementSibling?.textContent.trim() || '0';

      const transactionsFromHtml = Array.from(doc.querySelectorAll('td'))
        .find((el) => el.textContent === 'Fund Transaction Record')
        .closest('tr')?.nextElementSibling;
      const transactionsArray = Array.from(transactionsFromHtml.querySelectorAll('tr'));
      transactionsArray.shift();

      const transactionsMapped = transactionsArray.map((el, index) => {
        const tds = el.querySelectorAll('td');
        const applicationType = tds[1]?.textContent.slice(5) || '';
        let fundCode = parseNumberOrZero(tds[2]?.textContent || '0').toString();
        if (fundCode.length === 1) {
          fundCode = '0' + fundCode;
        }

        const amount = parseNumberOrZero(
          cleanTextContent(tds[3]?.textContent || '0.00').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const units = parseNumberOrZero(
          cleanTextContent(tds[4]?.textContent || '0.00').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const price = parseNumberOrZero(
          cleanTextContent(tds[5]?.textContent || '0.00').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);
        const runDate = tds[6]?.textContent || '0.00';
        const priceEffectiveDate = tds[7]?.textContent || '0.00';
        const balanceUnits = parseNumberOrZero(
          cleanTextContent(tds[8]?.textContent || '0').replace(/[^0-9.-]+/g, '')
        ).toFixed(2);

        const type = 'imported';
        const startDate = new Date().toJSON();
        return {
          type,
          fundCode,
          amount,
          units,
          price,
          runDate,
          priceEffectiveDate,
          balanceUnits,
          applicationType,
          startDate
        };
      });

      const parsedFundsData = dataRows
        .map((row) => {
          const cells = row.querySelectorAll('td');

          const fundName = cleanNumbersContent(cells[0]?.textContent || '0');
          const apportionment = parseNumberOrZero(
            cleanTextContent(cells[5]?.textContent || '0').replace(/,/g, '')
          ).toFixed(2);
          const amount = parseNumberOrZero(
            cleanTextContent(cells[1]?.textContent || '0').replace(/,/g, '')
          ).toFixed(2);
          const matchingFund = productFundsBase.find((fund) => fund.Name === fundName);

          if (matchingFund) {
            return {
              fund: { id: String(matchingFund.ID), num: amount || null },
              premium: { id: String(matchingFund.ID), num: apportionment || null }
            };
          }

          return null;
        })
        .filter((data) => data !== null);

      const funds = parsedFundsData.map((data) => data.fund).filter((fund) => fund.num !== null);
      const premiums = parsedFundsData
        .map((data) => data.premium)
        .filter((premium) => premium.num !== null);

      setFunds(funds);
      setPremiums(premiums);
      setTransactions(transactionsMapped);
      setPremiumType(premiumFrequency === 'Yearly' ? 'annually' : premiumFrequency.toLowerCase());
      setPremiumAmount(parseNumberOrZero(premiumCell.replace(',', '')));
      setStartDate(convertDateToString(startDateFromHtml));

      let welcomeBonusAmount = 0;
      transactionsArray.forEach((el) => {
        const tds = el.querySelectorAll('td');
        const type = tds[1]?.textContent || '';
        const amount = parseNumberOrZero(
          cleanTextContent(tds[3]?.textContent || '0').replace(/,/g, '')
        );
        if (type.includes('Welcome Bonus')) {
          welcomeBonusAmount += amount;
        }
      });

      let netInvestmentAmount = 0;
      transactionsArray.forEach((el) => {
        const tds = el.querySelectorAll('td');
        const type = tds[1]?.textContent || '';
        const amount = parseNumberOrZero(
          cleanTextContent(tds[3]?.textContent || '0').replace(/,/g, '')
        );
        if (type.includes('Net Investment Premium')) {
          netInvestmentAmount += amount;
        }
      });

      setAmount(netInvestmentAmount);
      setWelcomeBonus(welcomeBonusAmount);
    };

    reader.readAsText(file);
    onHtmlUploadClose();
  };

  useEffect(() => {
    const formatTransactions = (transactions) => {
      const regex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

      return transactions.map((transaction) => {
        let runDate;
        let priceEffectiveDate;
        if (regex.test(transaction.runDate) && regex.test(transaction.priceEffectiveDate)) {
          const [year, month, day] = transaction.runDate.split('-');
          runDate = new Date(`${year}-${month}-${day}T00:00:00`).toJSON();

          const [pYear, pMonth, pDay] = transaction.priceEffectiveDate.split('-');
          priceEffectiveDate = new Date(`${pYear}-${pMonth}-${pDay}T00:00:00`).toJSON();
        } else {
          const [day, month, year] = transaction.runDate.split('/');
          runDate = new Date(`${year}-${month}-${day}T00:00:00`).toJSON();

          const [pDay, pMonth, pYear] = transaction.priceEffectiveDate.split('/');
          priceEffectiveDate = new Date(`${pYear}-${pMonth}-${pDay}T00:00:00`).toJSON();
        }

        const amount = parseFloat(transaction.amount);
        const balanceUnits = parseFloat(transaction.balanceUnits);
        const fundCode = parseFloat(transaction.fundCode);
        const price = parseFloat(transaction.price);
        const units = parseFloat(transaction.units);
        const type = transaction.type;
        const startDate = transaction.startDate;

        return {
          ...transaction,
          type,
          amount,
          balanceUnits,
          fundCode,
          price,
          units,
          runDate,
          priceEffectiveDate,
          startDate
        };
      });
    };

    setFormattedTransactions(formatTransactions(transactions));
  }, [transactions]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onUpload,
    noClick: true,
    accept: 'text/html'
  });

  const fetchTransactions = async () => {
    try {
      const transactions = await getTransactions(productInfo.clientId, productInfo.ID);
      setTransactions(
        transactions.map((transaction) => {
          const formatToDate = (dateString) => {
            const date = new Date(dateString);
            if (!isNaN(date.getTime())) {
              return date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              });
            }
            return dateString;
          };
          const balanceUnits = parseFloat(transaction.balanceUnits).toFixed(2);
          return {
            ...transaction,
            runDate: formatToDate(transaction.runDate),
            priceEffectiveDate: formatToDate(transaction.priceEffectiveDate),
            fundCode:
              parseFloat(transaction.fundCode).toString().length === 1
                ? `0${transaction.fundCode}`
                : transaction.fundCode.toString(),
            balanceUnits: balanceUnits
          };
        })
      );

      function formatTransactions(transactions) {
        return transactions.map((transaction) => ({
          amount: parseFloat(transaction.amount),
          balanceUnits: parseFloat(transaction.balanceUnits),
          fundCode: parseFloat(transaction.fundCode),
          price: parseFloat(transaction.price),
          priceEffectiveDate: transaction.priceEffectiveDate,
          runDate: transaction.runDate,
          type: transaction.type,
          units: parseFloat(transaction.units),
          applicationType: transaction.applicationType,
          startDate: transaction.startDate
        }));
      }
      setFormattedTransactions(formatTransactions(transactions));
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    if (productInfo) {
      fetchTransactions();
      const fetchClient = async () => {
        const client = await getClient(productInfo.clientId);
        return client;
      };

      const initializeData = async () => {
        const client = await fetchClient();

        if (client.birthday) {
          setBirthday(new Date(client.birthday).toISOString().split('T')[0]);
        }

        const gender = client.gender;
        setCheckedGender(gender === 'male');
        const smoker = client.isSmoker;
        setCheckedSmoker(smoker);
        const name = client.name;
        setName(name);

        const fundsArray = Object.entries(productInfo.balances).map(([key, value]) => ({
          id: key,
          num: value
        }));

        setFunds(fundsArray);
        setPremiums(
          Object.entries(productInfo.premium.apportionment).map(([id, num]) => ({
            id: id.toString(),
            num: (parseFloat(num) * 100).toFixed(2)
          }))
        );
        setAmount(productInfo.totalInvested);
        setWelcomeBonus(productInfo.bonus);
        setPolicyFeeType(productInfo.premium.policyType);
        setStartDate(new Date(productInfo.CreatedAt).toISOString().split('T')[0]);
        setPremiumType(
          productInfo.premium.frequency === 'Yearly'
            ? 'annually'
            : productInfo.premium.frequency.toLowerCase()
        );
        setPremiumAmount(productInfo.premium.amount);
        setProductName(productInfo.name);
      };

      initializeData();
    }
  }, [productInfo]);

  return (
    <>
      <GridItem colSpan={{ base: 1, md: 1, lg: 1 }} w="100%" pb="15px" overflowAnchor="none">
        <Card
          direction="column"
          w="100%"
          h="160px"
          px="0px"
          border={`1px solid`}
          borderColor={borderColor}
        >
          <Flex px="20px" justify="space-between" mb="20px" flexDirection="column">
            <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
              Input product transaction history
            </Text>
            <Text
              color={helperTextColor}
              fontSize="16px"
              fontWeight="500"
              lineHeight="100%"
              padding="0.625rem 0"
            >
              DISCLAIMER: DO NOT PROVIDE ANY SENSITIVE INFORMATION
            </Text>
            <Flex justify={'space-evenly'} mt={'20px'}>
              <Button
                color={buttonTextColor}
                fontSize="16px"
                bg={bgButton}
                _hover={bgHover}
                onClick={() => {
                  setShowTransactions(true);
                  window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                  });
                }}
              >
                Import manually
              </Button>
              <Button
                color={buttonTextColor}
                fontSize="16px"
                bg={bgButton}
                _hover={bgHover}
                onClick={() => onHtmlUploadOpen()}
              >
                Import via file upload
              </Button>
            </Flex>
          </Flex>
        </Card>
      </GridItem>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
        mb="15px"
        border={`1px solid ${borderColor}`}
      >
        <Flex px="20px" justify="space-between" mb="20px" flexDirection="column">
          <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
            Input Existing Client Details
          </Text>
          <Text
            color={helperTextColor}
            fontSize="16px"
            fontWeight="500"
            lineHeight="100%"
            padding="0.625rem 0"
          >
            DISCLAIMER: DO NOT PROVIDE ANY SENSITIVE INFORMATION
          </Text>
        </Flex>
        <Flex px="20px" justify="space-between" mb="20px" gap="20px">
          <Box
            direction="column"
            w="100%"
            padding="16px"
            borderRadius="8px"
            bg={cardColor}
            border={`1px solid ${borderColor}`}
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Name/Alias
            </Text>
            <Input
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius="30px"
              placeholder=""
              onChange={handleName}
              value={name}
              isInvalid={name === ''}
              p="0"
              m="0"
              pl="15px"
            />
          </Box>
          <Box
            direction="column"
            w="100%"
            padding="16px"
            borderRadius="8px"
            bg={cardColor}
            border={`1px solid ${borderColor}`}
            css={css`
              input::-webkit-datetime-edit-day-field:focus,
              input::-webkit-datetime-edit-month-field:focus,
              input::-webkit-datetime-edit-year-field:focus {
                color: ${inputText};
                outline: none;
              }
              input[type='date']:focus {
                color: ${inputTextPlaceholder};
                outline: none;
              }
            `}
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Birthday
            </Text>
            <Input
              fontSize="sm"
              bg={inputBg}
              color={inputText}
              fontWeight="500"
              _placeholder={{ color: 'black', fontSize: '14px', backgroundColor: 'black' }}
              borderRadius="30px"
              placeholder="DD-MM-YYYY e.g. 20-01-1999"
              type="date"
              onChange={handleBirthday}
              value={birthday}
              className="date-picker"
              css={css`
                ::-webkit-calendar-picker-indicator {
                  background: url(${calendarIcon}) center/80% no-repeat;
                  color: ${inputText};
                }
              `}
              isInvalid={birthday === ''}
              border={birthday === '' ? '1px solid #FC8181' : '1px solid'}
            />
          </Box>
        </Flex>

        <Flex px="20px" justify="space-between" mb="20px" gap="20px">
          <Box
            direction="column"
            w="100%"
            px="0px"
            padding="16px"
            borderRadius="8px"
            bg={cardColor}
            border={`1px solid ${borderColor}`}
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Gender
            </Text>

            <Stack spacing={2}>
              <Checkbox isChecked={checkedGender} onChange={handleCheckMale}>
                Male
              </Checkbox>
              <Checkbox isChecked={!checkedGender} onChange={handleCheckFemale}>
                Female
              </Checkbox>
            </Stack>
          </Box>
          <Box
            direction="column"
            w="100%"
            px="0px"
            padding="16px"
            borderRadius="8px"
            bg={cardColor}
            border={`1px solid ${borderColor}`}
          >
            <Text
              color={textColor}
              fontSize="16px"
              fontWeight="700"
              lineHeight="100%"
              marginBottom="0.625rem"
            >
              Smoker Status
            </Text>

            <Stack spacing={2}>
              <Checkbox isChecked={checkedSmoker} onChange={handleCheckSmoker}>
                Smoker
              </Checkbox>
              <Checkbox isChecked={!checkedSmoker} onChange={handleCheckNonSmoker}>
                Non-Smoker
              </Checkbox>
            </Stack>
          </Box>
        </Flex>
        <AddFund
          textColor={textColor}
          inputBg={inputBg}
          inputText={inputText}
          handleAmount={handleAmount}
          amount={amount}
          handleStartDate={handleStartDate}
          startDate={startDate}
          handleProductName={handleProductName}
          productName={productName}
          funds={funds}
          fundOptions={fundOptions}
          addFund={addFund}
          removeFund={removeFund}
          selectFund={selectFund}
          selectNumUnit={selectNumUnit}
          handlePremiumAmount={handlePremiumAmount}
          premiumAmount={premiumAmount}
          handlePremiumType={handlePremiumType}
          premiumType={premiumType}
          premiums={premiums}
          addPremium={addPremium}
          removePremium={removePremium}
          selectAllocation={selectAllocation}
          selectPremiumFund={selectPremiumFund}
          handlePolicyFeeType={handlePolicyFeeType}
          policyFeeType={policyFeeType}
          handleWelcomeBonus={handleWelcomeBonus}
          welcomeBonus={welcomeBonus}
          setDisableButton={setDisableButton}
          inputTextPlaceholder={inputTextPlaceholder}
        />
        <ConfirmModal
          isOpen={isConfirmOpen}
          onClose={onConfirmClose}
          onSubmit={() => {
            handleCreateProduct()
              .then((productInfo) => {
                if (productInfo) {
                  const productId = productInfo['ID'];
                  const clientId = productInfo['clientId'];
                  return handleCreateTransactions(productId, clientId);
                }
              })
              .catch((err) => {
                console.error('Error in submission:', err);
                toastError('Error in submission');
              });
          }}
          message={message}
          disabled={disableConfirm}
          buttonText="Continue editing"
        />
      </Card>

      <TransactionTable
        transactions={transactions}
        bgButton={bgButton}
        bgHover={bgHover}
        textColor={textColor}
        helperTextColor={helperTextColor}
        showTransactions={showTransactions}
        setTransactions={setTransactions}
        buttonTextColor={buttonTextColor}
        inputBg={inputBg}
        inputText={inputText}
        setDisableConfirm={setDisableConfirm}
        borderColor={borderColor}
        inputTextPlaceholder={inputTextPlaceholder}
      />

      <Flex px="25px" justify="center" width="100%">
        <Button
          color={buttonTextColor}
          fontSize="16px"
          bg={bgButton}
          _hover={bgHover}
          onClick={handleSubmit}
          disabled={disableButton === 'null' || disableConfirm}
          my={productInfo ? '20px' : '0'}
        >
          Confirm
        </Button>
      </Flex>

      {/* Add help info modal */}
      <Modal isOpen={isAddProductOpen} onClose={onAddProductClose} w="0" maxW="0">
        <ModalOverlay />
        <ModalContent w="900px" maxW="900px">
          <ModalBody p="0" w="900px">
            <Box>
              <img src={helpInfoModal} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <UploadModal
        isOpen={isHtmlUploadOpen}
        onClose={onHtmlUploadClose}
        onOpenSecondaryModal={onAddProductOpen}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        textColor={textColor}
        helperTextColor={helperTextColor}
        openFileDialog={open}
      />
      <Toaster />
    </>
  );
}
